// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    version: '2.1.58',
    comunEndpoint: 'https://int.ms-comun.digo.tecalis.dev/',
    rmEndpoint: 'https://int.ms-repmovil.digo.tecalis.dev/',
    simyoEndPoint: 'https://int.ms-simyo.digo.tecalis.dev/',
    orangeEndPoint: 'https://int.ms-orange.digo.tecalis.dev/',
    oldDigoEndPoint: 'https://int-b.dev.tecalis.com/',
    digoEndPoint: 'https://int.digo.tecalis.dev/',
    masmovilEndpoint: 'https://ms-masmovil.lamarr.digo.tecalis.dev',
    digoApiKey: 'theyUJu3tes@efaswejahEZ@afR5Fenu',
    googleMapsCountry: 'es',
    showRM: true,
    showSimyoTerminals: true,
    piloto: 'piloto_pdv',
    showSkipKYC: true,
    contractNewBrands: 'https://agents.sta.masstack.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
