import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasmovilRoutingModule } from './masmovil-routing.module';
import { MasmovilListadoActivacionesComponent } from './components/masmovil-listado-activaciones/masmovil-listado-activaciones.component';
import { MasmovilOrderDetailComponent } from './components/masmovil-order-detail/masmovil-order-detail.component';
import { MasmovilSubheaderComponent } from './components/masmovil-subheader/masmovil-subheader.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';


@NgModule({
    declarations: [
        MasmovilListadoActivacionesComponent,
        MasmovilOrderDetailComponent,
        MasmovilSubheaderComponent
    ],
    exports: [
        MasmovilSubheaderComponent
    ],
    imports: [
        CommonModule,
        MasmovilRoutingModule,
        NgbDropdownModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class MasmovilModule { }
