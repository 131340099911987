import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {AuthService} from 'src/app/shared/services/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {brands, fibraSpeeds, mobileContractType, orderType, technologyTypes, typeCard} from '../../shared/constantes';
import {descuento, SimyoGlobals, terminalStatus} from '../classes/simyo-globals';
import {SimyoRateService} from './simyo-rate.service';
import {masterAllResponseSimyo, masterAllSimyo} from '../models/masterAllSimyo';
import {uploadFileResponse} from 'src/app/shared/models/uploadFileResponse';
import {orderListRequest, orderListResponseSimyo, orderListStatsResponseSimyo} from '../models/orderListSimyo';
import { responseReuseIVAccessSimyo, reuseIVAccessSimyo } from '../models/installationVirtual';
import { map, tap } from 'rxjs/operators';
import {SimyoOrderService} from "./simyo-order.service";
import { PermissionService } from 'src/app/shared/services/permission.service';

@Injectable({
    providedIn: 'root'
})
export class SimyoService {

    // Masters
    private provincesCommun: any;
    private roadTypesCommun: any;
    private masterAll: BehaviorSubject<masterAllSimyo>;

    // Order
    private typeOrder: any;
    private rateFtth: any;
    private rateFtthObservable: any;
    private rateMobiles: any[];
    private rateMobileObservable: any;
    private mobilePacks: any[];
    private mobilePacksObservable: any;
    private preventDestroyObservable: any;
    private swapSim: any;

    private userData: any;
    private formsData: any;
    private resultadosCustomer: any;
    private resultadosNotCustomer: any;
    private customerPromotion: any;

    private indexToRemove: BehaviorSubject<boolean>;

    private changeMobilePack: boolean;
    private isChanging = false;
    private nebaNoCommercial = false;
    private zip_code = '';
    private taxType = '';

    private virtualInstallationData: reuseIVAccessSimyo = {
        brand: brands.simyo,
        customerName: null,
        documentNumber: null,
        documentType: null,
        gescal37: null,
        footprint: technologyTypes.dir
    };

    private canVirtualInstallation: boolean = false;
    private responseReuseIVAccess: responseReuseIVAccessSimyo = null;

    private customerInfo: any = null;
    private allRates: any = null;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private rateService: SimyoRateService,
        public simyoGlobals: SimyoGlobals,
        public permissionService: PermissionService
    ) {
        this.rateMobiles = [];
        this.mobilePacks = [];
        this.typeOrder = new BehaviorSubject<string>('convergent');
        this.rateFtthObservable = new BehaviorSubject<any>(null);
        this.rateMobileObservable = new BehaviorSubject<any>([]);
        this.mobilePacksObservable = new BehaviorSubject<any>([]);
        this.preventDestroyObservable = new BehaviorSubject(false);
        this.userData = new BehaviorSubject<any>(null);
        this.formsData = new BehaviorSubject<any>(null);
        this.indexToRemove = new BehaviorSubject<any>(null);
        this.resultadosCustomer = new BehaviorSubject<any>(null);
        this.resultadosNotCustomer = new BehaviorSubject<any>(null);
        this.customerPromotion = new BehaviorSubject<any>(null);
        this.swapSim = new BehaviorSubject<any>(null);
        this.changeMobilePack = true;

        // Masters
        this.provincesCommun = new BehaviorSubject(null);
        this.roadTypesCommun = new BehaviorSubject(null);
        this.masterAll = new BehaviorSubject<masterAllSimyo>(null);

        this.rateService.getRates().subscribe(data => {
            if(data) {
                this.allRates = data;
            }
        });
    }

    // Masters service
    getProvincesCommunFromService() {
        return this.http.get(environment.comunEndpoint + 'coverage/provinces', {headers: this.getHeaders()});
    }

    getRoadTypeCommunFromService() {
        return this.http.get(environment.comunEndpoint + 'coverage/road-types', {headers: this.getHeaders()});
    }

    getCustomerInfoFromService(customerInfo) {
        let body = {...customerInfo, permissions: this.permissionService.getPermissionSimyoRates()};
        return this.http.post(environment.simyoEndPoint + 'customer/info', body, {headers: this.getHeaders()}).pipe(

            tap((response:any) => {
                if (response.msg) {
                    this.customerInfo = response.msg;
                    return
                }
                this.customerInfo = null;
            })
        );
    }

    getTerminals() {
        return this.http.get(environment.simyoEndPoint + 'terminals', {headers: this.getHeaders()});
    }

    public getContract(order_id: number): Observable<any> {
        return this.http.get(environment.simyoEndPoint + 'order/' + order_id + '/document/contract/download', {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    public getSepa(order_id) {
        return this.http.get(environment.simyoEndPoint + 'order/' + order_id + '/document/sepa/download', {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    public getFileUpload(order_id, assetId) {
        return this.http.get(environment.simyoEndPoint + 'order/' + order_id + '/document/' + assetId + '/download', {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    public getFilteredOrderList(filters: orderListRequest): Observable<orderListResponseSimyo> {
        return this.http.post<orderListResponseSimyo>(environment.simyoEndPoint + 'order/list/filter', filters, {headers: this.getHeaders()}).pipe(
            map((data:orderListResponseSimyo) => {
                if (data && data.msg && data.msg.data) {
                    let orders = data.msg.data.map(order =>{
                        if (order.mobiles) {
                            return {...order, mobiles: order.mobiles.map(mobile => {return {...mobile, is_esim: mobile.is_esim ? typeCard.esim : typeCard.sim}})};
                        }
                        else {
                            return order;
                        }
                    });
                    data.msg.data = orders;
                }
                return data;
            })
        );
    }

    public getFilterAlerts(filters: string): Observable<orderListResponseSimyo> {
        return this.http.get<orderListResponseSimyo>(environment.simyoEndPoint + 'order/list/alerts' + filters, {headers: this.getHeaders()});
    }

    public getOrderListStats(): Observable<orderListStatsResponseSimyo> {
        return this.http.post<orderListStatsResponseSimyo>(environment.simyoEndPoint + 'order/list/stats', null, {headers: this.getHeaders()});
    }

    public updateAnnotation(order_id: number, annotation: string): Observable<any> {
        return this.http.patch<any>(environment.simyoEndPoint + 'order/' + order_id + '/annotation', {annotation}, {headers: this.getHeaders()});
    }

    getMasterAllFromService(): Observable<masterAllResponseSimyo> {
        return this.http.get<masterAllResponseSimyo>(environment.simyoEndPoint + 'master/all', {headers: this.getHeaders()});
    }

    public uploadFile(file: File, orderId: number, documentUploadType?: string, documentUploadId?: number): Observable<uploadFileResponse> {
        const formData = new FormData();
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });

        formData.append('document', file);
        if (documentUploadType && documentUploadType !== '') {
            formData.append('document_upload_type', documentUploadType);
        }
        if (Number.isInteger(documentUploadId)) {
            formData.append('document_upload_id', documentUploadId.toString());
        }
        return this.http.post<uploadFileResponse>(environment.simyoEndPoint + 'order/' + orderId + '/document/upload', formData, {headers: header});
    }

    public uploadFileMultiple(files: File[], orderId: number, documentUploadType?: string, documentUploadId?: number): Observable<uploadFileResponse> {
        const formData = new FormData();
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });

        Array.from(files).forEach((file, index) => {
            formData.append('document' + index, file, file.name);
        });
        if (documentUploadType && documentUploadType !== '') {
            formData.append('document_upload_type', documentUploadType);
        }
        if (Number.isInteger(documentUploadId)) {
            formData.append('document_upload_id', documentUploadId.toString());
        }
        return this.http.post<uploadFileResponse>(environment.simyoEndPoint + 'order/' + orderId + '/document/upload-multiple', formData, {headers: header});
    }

    public uploadFileContract(files: File[], orderId: number, documentUploadId?: any): Observable<uploadFileResponse> {
        const formData = new FormData();
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });

        Array.from(files).forEach((file, index) => {
            formData.append('document' + index, file, file.name);
        });
        if (documentUploadId ?? false) {
            formData.append('document_upload_id', documentUploadId);
        }
        return this.http.post<uploadFileResponse>(environment.simyoEndPoint + 'order/' + orderId + '/document/upload-contract', formData, {headers: header});
    }

    removeOrder(id_remove) {
        const url = environment.simyoEndPoint + 'order/' + `${id_remove}` + '/delete';
        return this.http.delete(url, {headers: this.getHeaders()});
    }

    iccidValidator(number) {
        return this.http.post(environment.simyoEndPoint + 'iccid/validator', {iccid: number}, {headers: this.getHeaders()});
    }

    iccidAvailable(number) {
        return this.http.post(environment.simyoEndPoint + 'iccid/available', {iccid: number}, {headers: this.getHeaders()});
    }

    // Master observers
    setProvincesCommun(data) {
        this.provincesCommun.next(data);
    }

    getProvincesCommun() {
        return this.provincesCommun.asObservable();
    }

    setRoadTypesCommun(data) {
        this.roadTypesCommun.next(data);
    }

    getRoadTypesCommun() {
        return this.roadTypesCommun.asObservable();
    }

    setMasterAll(data: masterAllSimyo) {
        this.masterAll.next(data);
    }

    getMasterAll(): Observable<masterAllSimyo> {
        return this.masterAll.asObservable();
    }

    // Customer
    setDataFromCustomer(data) {
        this.resultadosCustomer.next(data);
    }

    getDataFromCustomer() {
        return this.resultadosCustomer.asObservable();
    }

    setDataFromNotCustomer(data) {
        this.resultadosNotCustomer.next(data);
    }

    getDataFromNotCustomer() {
        return this.resultadosNotCustomer.asObservable();
    }

    setCustomerPromotion(data) {
        if (data) {
            this.customerPromotion.has_ftth = data.has_ftth;
            this.customerPromotion.has_mobile_promotion = data.has_mobile_promotion;
        }
        this.customerPromotion.next(data);
    }

    getCustomerPromotion() {
        return this.customerPromotion.asObservable();
    }

    // Tipo de orden
    setTypeOrder(data) {
        this.typeOrder.next(data);
    }

    getTypeOrder() {
        return this.typeOrder.asObservable();
    }

    // Servicios auxiliares
    // Tarifa Fibra
    setRateFtth(data) {
        this.rateFtth = data
        if(data && data.type_normal && data.type_discount) {
            let typeRate = Object.keys(data).find(key => data[key]?.rate_id && data[key].rate_id === data.rate_id);
            this.rateFtth.front_discount = typeRate === 'type_discount' ? (+data.type_normal.price - +data.type_discount.price) : 0;
            this.rateFtth.front_total_without_discount = data.type_normal.price;
            this.rateFtth.front_type_discount = typeRate === 'type_discount' ? (this.customerInfo?.has_package ? 'additional_package' : 'first_package') : null; //null|first_package|additional_package
        }
        this.rateFtthObservable.next(this.rateFtth);
    }

    getRateFtth() {
        return this.rateFtthObservable.asObservable();
    }

    setIsChanging(changing) {
        this.isChanging = changing;
    }

    getIsChanging() {
        return this.isChanging;
    }

    // Tarifa(s) móvil(es)
    setRatesMobile(index, value, order?) {
        if (typeof this.rateMobiles[index] === 'undefined') {
            this.rateMobiles.push(value);
        } else {
            this.rateMobiles[index] = value;
        }

        this.rateMobiles = this.calculateMainLine(this.rateMobiles);
        this.rateMobileObservable.next(this.rateMobiles);
    }

    setNewRatesMobile() {
        this.rateMobiles = [];
    }

    setTypePayRatesMobile(index, value) {
        if (this.rateMobiles && this.rateMobiles.length && this.rateMobiles[index]) {
            this.rateMobiles[index].type_pay = value;
            this.rateMobiles = this.calculateMainLine(this.rateMobiles);
            this.rateMobileObservable.next(this.rateMobiles);
        }
    }

    setTerminal(index, value) {
        if (typeof this.rateMobiles[index] !== 'undefined') {
            this.rateMobiles[index].terminal = value;
            this.rateMobiles = this.calculateMainLine(this.rateMobiles);
        this.rateMobileObservable.next(this.rateMobiles);
        }
    }

    removeTerminal(index) {
        if (typeof this.rateMobiles[index] !== 'undefined') {
            this.rateMobiles[index].terminal = null;
            this.rateMobiles = this.calculateMainLine(this.rateMobiles);
        this.rateMobileObservable.next(this.rateMobiles);
        }
    }

    addFirstRatesMobile(value) {
        this.rateMobiles = [];
        this.rateMobiles.unshift(value);
        this.rateMobiles = this.calculateMainLine(this.rateMobiles);
        this.rateMobileObservable.next(this.rateMobiles);
    }

    removeFirstRatesMobile() {
        if (this.rateMobiles.length > 0) {
            this.rateMobiles.shift();
            this.rateMobiles = this.calculateMainLine(this.rateMobiles);
            this.rateMobileObservable.next(this.rateMobiles);
        }
    }

    getRatesMobile() {
        return this.rateMobileObservable.asObservable();
    }

    setChangeMobilePack(mobilePack: boolean){
        this.changeMobilePack = mobilePack;
    }

    getChangeMobilePack(){
        return this.changeMobilePack;
    }

    setMobilePacks(index, value) {
        //this.setChangeMobilePack(false);
        if (typeof this.mobilePacks[index] === 'undefined') {
            this.mobilePacks.push(value);
        } else {
            this.mobilePacks[index] = value;
        }
        this.mobilePacksObservable.next(this.mobilePacks);
    }

    getMobilePacks() {
        return this.mobilePacksObservable.asObservable();
    }

    getMobilePacksObject() {
        return this.mobilePacks;
    }

    removeLine(index) {
        this.rateMobiles.splice(index, 1);
        this.rateMobiles = this.calculateMainLine(this.rateMobiles)
        this.rateMobileObservable.next(this.rateMobiles);
        this.mobilePacks.splice(this.mobilePacks.length - 1);
        this.mobilePacksObservable.next(this.mobilePacks);
    }

    getIndexToRemove() {
        return this.indexToRemove.asObservable();
    }

    setIndexToRemove(index) {
        this.indexToRemove.next(index);
        setTimeout(() => this.indexToRemove.next(null));
    }

    // AUX
    getPreventDestroy() {
        return this.preventDestroyObservable.asObservable();
    }

    setPreventDestroy(data: boolean) {
        this.preventDestroyObservable.next(data);
    }

    // RESET
    resetMobile() {
        this.rateMobiles = [];
        this.mobilePacks = [];
        this.rateMobileObservable.next(this.rateMobiles);
        this.mobilePacksObservable.next(this.mobilePacks);
    }

    // Iniciar nueva tramitación desde otra
    setUserData(data) {
        this.userData.next(data);
    }

    getUserData() {
        return this.userData.asObservable();
    }

    setFormsData(data) {
        this.formsData.next(data);
    }

    getFormsData() {
        return this.formsData.asObservable();
    }

    setSwapSim(data) {
        this.swapSim.next(data);
    }

    getSwapSim() {
        return this.swapSim.asObservable();
    }

    isAllowDiscount(mobiles?, type?, speed?, customerData?): boolean {
        let discount = false;
        const typeOrder = type ? type : this.typeOrder.value;
        const customerPromotion = customerData ? customerData : this.customerPromotion.value;
        mobiles = mobiles ? mobiles : this.rateMobiles;
        let minimumPrice = 7; //speed == fibraSpeeds.speed_100 ? 10 : 7;
        if (typeOrder === orderType.CONVERGENT) {
            discount = false;
            if ((this.getShowFtthReduced()) && this.canPackage(mobiles, minimumPrice)) {
                discount = true;
            }
            if ((this.getShowFtthReduced()) && this.getMainLine()) {
                discount = true;
            }
            mobiles.forEach(mobile => {
                if (this.getShowFtthReduced() && mobile.contract_type === 'postpaid') {
                    discount = true;
                }
            });
        }
        if (typeOrder === orderType.BROADBAND && speed) {
            if (customerPromotion) {
                let mainLineMatrix = this.searchMainLineInMatrix(this.getMainLineWithoutSpeed());
                discount = this.getShowFtthReduced() && mainLineMatrix?.rate_info.available_in.includes(`ftth_${speed}`);
            }
        }
        if (typeOrder === orderType.MOBILE) {
            if (customerPromotion && mobiles && mobiles.length) {
                const filterRatesMobile = this.isNumBroadbandMoreThanMobile(customerPromotion.num_ftth, customerPromotion.num_mobile.gt7);

                if (filterRatesMobile) {
                    mobiles.forEach((mobile, index) => {
                        let priceBonos = 0;
                        /*if (mobile.movil) {
                            priceBonos = this.simyoGlobals.pricesBonos(mobile.bonos);
                        } else if (mobile.rate?.rate_info) {
                            mobile.bonos.forEach(x => {
                                priceBonos += +x.rate.rate_info.price;
                            });
                        } else {
                            priceBonos = this.simyoGlobals.pricesBonos(mobile.rate?.bonos);
                        }*/

                        let rateInfo = this.simyoGlobals.findByKeyInMobile(mobile, 'rate_info');
                        // Discount que muestra el mensaje cuando es solo movil que tienen un descuento por paquete con fibra existente
                        if (rateInfo && index === 0 && ((+rateInfo.price + priceBonos) >= descuento)/* && availableMobileDiscount > 0*/) {
                            //mobile.discount = true;
                            discount = true;
                            // availableMobileDiscount--;
                        } else {
                            //if (mobile)
                            //mobile.discount = false;
                        }
                    });
                }
            }
        }
        return discount;
    }

    canPackage(mobiles, minimumPrice = 7){
        mobiles.forEach(movil => {
            if (movil?.contract_type === 'postpaid' && +movil?.rate_info?.price >= minimumPrice) {
                return true;
            }
        });
        return false;
    }

    moreMobileThanBroadband(speed){
        const customerData = this.customerPromotion.value;

        if (!customerData) {
            return false;
        }

        return this.isNumMobileMoreThanBroadband(customerData.num_ftth, customerData.num_mobile, speed);
    }

    terminalsAreValid(order) {
        this.getTerminals()
            .subscribe((data: any) => {
                if (data && data.msg) {
                    order.mobiles.forEach((movil) => {
                        if (movil.terminal_oid) {
                            const found = data.msg.find(terminal => {
                                const optionsTerminal = terminal.terminal_oids.filter((terminalConf: any) => {
                                    let typeOperation, typePay, terminaOid;
                                    typeOperation = terminalConf.portability === movil.portability;
                                    typePay = terminalConf.pay_type === movil.contract_type;
                                    terminaOid = movil.terminal_oid_id === terminalConf.terminal_oid_id;
                                    return typeOperation && typePay && terminaOid && terminal.state === terminalStatus.AVAILABLE;
                                });
                                if (optionsTerminal && optionsTerminal.length) {
                                    return true;
                                }
                            });
                            if (found) {
                                movil.isValidTerminal = true;
                                movil.terminal_oid.terminal.terminal_oids = found.terminal_oids;
                            } else {
                                movil.isValidTerminal = false;
                            }
                        }
                    });
                }
            }, () => {
                order.mobiles.forEach((movil) => {
                    if (movil.terminal_oid) {
                        movil.isValidTerminal = false;
                    }
                });
            });
    }

    showDiscountForAdditional(customerData, type, speed?): boolean {
        var ftth = customerData?.num_ftth;
        var total_ftth = (ftth?.ftth_100 + ftth?.ftth_300 + ftth?.ftth_500 + ftth?.ftth_600 + ftth?.ftth_1);
        var total_ftth_discount = (ftth?.ftth_100_discount + ftth?.ftth_300_discount + ftth?.ftth_500_discount + ftth?.ftth_600_discount + ftth?.ftth_1_discount);
        var total_mobile = customerData?.num_mobile?.gt7;

        if (!customerData || !speed) {
            return false;
        }

        if (type === orderType.CONVERGENT || type === orderType.BROADBAND) {

            /*if(speed && speed == fibraSpeeds.speed_100) {
                return (total_mobile > total_ftth && customerData?.num_mobile?.gt10 > 0);
            }*/
            return (total_mobile > total_ftth);
        }

        if (type === orderType.MOBILE) {

            if (total_ftth > 0 && total_ftth_discount > 0 && (total_ftth - total_ftth_discount == 0)) {
                return true;
            }

        }
        /*if (type === orderType.CONVERGENT && customerData && (customerData.num_ftth - customerData.num_mobile) < 0) {
            return true;
        }*/
        return false;
    }

    setTaxType(zipcode){
        this.zip_code = zipcode + '';
        if (this.zip_code && this.zip_code != '') {
            var tax = this.zip_code.slice(0,2);
                if (tax === '35' || tax === '38') {
                    this.taxType = 'igic';
                    return 'igic';
                }
                if (tax === '51') {
                    this.taxType = 'ipsi_10';
                    return 'ipsi_10';
                }
                if (tax === '52') {
                    this.taxType = 'ipsi_8';
                    return 'ipsi_8';
                }
        }
        this.taxType = 'iva';
        return 'iva';
    }

    getTaxType(){
        return this.taxType;
    }

    getZipCode(){
        return this.zip_code;
    }

    getNebaNoCommercial() {
        return this.nebaNoCommercial; // TODO revisar
    }

    setNebaNoCommercial(neba) {
        this.nebaNoCommercial = neba;
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }

    private getHeadersPdf(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });
    }

    queryEsimQR(customer_id, msisdn) {
        return this.http.post(environment.simyoEndPoint + 'order/queryEsimQR', {customer_id, msisdn}, {headers: this.getHeaders()});
    }

    sendEsimQR(body) {
        return this.http.post(environment.simyoEndPoint + 'order/sendEsimQR', body, {headers: this.getHeaders()});
    }
    public setVirtualInstallationData(value) {
        this.virtualInstallationData = {...this.virtualInstallationData ,...value};
    }

    public getVirtualInstallationData() {
        return this.virtualInstallationData;
    }

    public setResponseVirtualInstallationData(value) {
        this.responseReuseIVAccess = {...this.responseReuseIVAccess ,...value};
    }

    public getResponseVirtualInstallationData() {
        return this.responseReuseIVAccess;
    }

    public clearVirtualInstallationData() {
        this.responseReuseIVAccess = null;
        this.virtualInstallationData = {
            brand: brands.simyo,
            customerName: null,
            documentNumber: null,
            documentType: null,
            gescal37: null,
        };
        this.setCanVirtualInstallation(false);
    }

    setCanVirtualInstallation(value) {
        this.canVirtualInstallation = value;
    }

    getCanVirtualInstallation() {
        return this.canVirtualInstallation;
    }

    public postVirtualInstallationData(): Observable<responseReuseIVAccessSimyo> {
        return this.http.post(`${environment.simyoEndPoint}order/reuseIVAccess`, this.virtualInstallationData, {headers: this.getHeaders()});
    }

    public postConfirmVirtualInstallation(value): Observable<responseReuseIVAccessSimyo> {
        return this.http.post(`${environment.simyoEndPoint}order/confirmIVAccess`, {registerId: this.responseReuseIVAccess.registerId, ivFrontal: value}, {headers: this.getHeaders()});
    }

    searchPdVByUsername(username) {
        return this.http.post(environment.comunEndpoint + 'digo/user/info', {username}, {headers: this.getHeaders()});
    }

    isNumMobileMoreThanBroadband(num_ftth, mobiles, speed) {
        let sumFtth = num_ftth.ftth_100
                + num_ftth.ftth_300
                + num_ftth.ftth_500
                + num_ftth.ftth_600
                + num_ftth.ftth_1;
        let freeMobiles = mobiles.gt7 - sumFtth;
        /*if(speed == fibraSpeeds.speed_100 && freeMobiles > 0) {
            return mobiles.gt10 > 0;
        }*/
        return freeMobiles > 0;
    }

    isNumBroadbandMoreThanMobile(num_ftth, numMobiles) {
        let sumFtth = num_ftth.ftth_100
                + num_ftth.ftth_300
                + num_ftth.ftth_500
                + num_ftth.ftth_600
                + num_ftth.ftth_1;
        return sumFtth > numMobiles;
    }

    getCustomer() {
        return this.customerInfo ?? null;
    }

    getMaxMobileCustomer() {
        return this.customerInfo ? this.customerInfo.max_mobile : null;
    }

    customerHasPackage() {
        if(this.customerInfo) {
            return this.customerInfo.has_package;
        }

        return this.getMainLine() !== null;
    }

    customerHasPackageOrMainLine() {
        if(this.customerInfo) {
            if(this.customerInfo.has_package) {
                return true;
            }
        }

        return this.getMainLine() !== null;
    }

    getShowFtthReduced() {
        if(this.getMainLine() !== null || this.getMainLineWithoutSpeed() !== null) {
            if(this.getCustomer() == null) {
                return true;
            }
            let totalFtth = this.getCustomer().num_ftth.total_ftth ?? 0;
            return totalFtth < 3;
        }
        return false;
    }

    getMaxBroadbandCustomer() {
        return this.customerInfo ? this.customerInfo.max_broadband : null;
    }

    getMobileMaxPriceCurrentOrder(onlyCustomer = false) {
        let maxSpeedFtth = this.getMaxFtthSpeed();
        let allowed18GB = this.allowedRate18GBNormalPrice(onlyCustomer);
        return this.rateMobiles.reduce((max, mobile, index) => {
            let currentMobilePrice = (mobile.rate_info.old_price != null && mobile.rate_info.old_price != '') ? +mobile.rate_info.old_price : +mobile.rate_info.price;
            let maxMobilePrice = max.mobile !== null ? ((max.mobile.rate_info.old_price != null && max.mobile.rate_info.old_price != '') ? +max.mobile.rate_info.old_price : +max.mobile.rate_info.price) : 0;

            let rateNoAdditional = mobile;
            if(mobile.rate_ids.refered !== '' ) {
                rateNoAdditional = this.allRates.RATES.MOBILE.find(rateMobile => rateMobile.rate_ids.postpaid == mobile.rate_ids.refered
                    || rateMobile.rate_ids.prepaid == mobile.rate_ids.refered)
            }
            if (mobile.contract_type === mobileContractType.prepaid
                || mobile.type_pay === mobileContractType.prepaid
                || (mobile.contract_type === mobileContractType.postpaid && ((allowed18GB && rateNoAdditional.data == '18 GB' && rateNoAdditional.voice == 'ILIMITADOS') == false && rateNoAdditional.rate_info.available_in.includes(`ftth_${maxSpeedFtth}`) == false))
            ) {
                return max;
            }
            if (currentMobilePrice > maxMobilePrice) {
                 return {mobile, index, beforeMobile: max};
            }
            return {...max, beforeMobile: mobile};
        }, {mobile: null, index: -1, beforeMobile: null});
    }

    /*
    * Funcion para saber si la linea principal esta entre las nuevas en el carrito
    */
    calculateMainLine(newMobiles) {
        if(newMobiles.length == 0) {
            return newMobiles.map(mobile => {
                return this.generateMobile(null, mobile);
            });
        }
        let maxSpeedFtth = this.getMaxFtthSpeed();
        if(maxSpeedFtth == '0' || (this.customerHasPackage() == false && this.allRates.PARAMS.FTTH_ACTIVE.includes(maxSpeedFtth) == false)) {
            //No hay fibra seleccionada o contratada
            return newMobiles.map(mobile => {
                return this.generateMobile(null, mobile);
            });
        }
        //search max price mobile current order
        let mobileMaxPriceCurrentOrder = this.getMobileMaxPriceCurrentOrder(true);
        //current max mobile
        let currentMaxMobile = this.getMaxMobileCustomer();

        let hasMainLine = false;
        //Inicializar linea principal
        newMobiles = newMobiles.map(mobile => { return {...mobile, is_main_line: false, type_pay: mobile.type_pay ? mobile.type_pay : mobile.contract_type}});
        let allowed18GB = this.allowedRate18GBNormalPrice(true);
        let mobileMaxPrice = newMobiles[mobileMaxPriceCurrentOrder.index];
        //Comprobar precios maximos y cambiar la linea principal si fuese necesario
        if (currentMaxMobile !== null && mobileMaxPriceCurrentOrder.index >= 0) {
            let maxPrice = (mobileMaxPriceCurrentOrder.mobile.rate_info.old_price != null && mobileMaxPriceCurrentOrder.mobile.rate_info.old_price != '') ? +mobileMaxPriceCurrentOrder.mobile.rate_info.old_price : +mobileMaxPriceCurrentOrder.mobile.rate_info.price;
            if (maxPrice > +currentMaxMobile.price || (maxPrice == +currentMaxMobile.price && currentMaxMobile.is_additional)) {
                newMobiles[mobileMaxPriceCurrentOrder.index].is_main_line = mobileMaxPrice.rate_info.available_in.includes(`ftth_${maxSpeedFtth}`) || (allowed18GB && mobileMaxPrice.data == '18 GB' && mobileMaxPrice.voice == 'ILIMITADOS');
            }
            hasMainLine = true;
        } else if(mobileMaxPriceCurrentOrder.index >= 0 && mobileMaxPrice) {
            newMobiles[mobileMaxPriceCurrentOrder.index].is_main_line = mobileMaxPrice.rate_info.available_in.includes(`ftth_${maxSpeedFtth}`) || (allowed18GB && mobileMaxPrice.data == '18 GB' && mobileMaxPrice.voice == 'ILIMITADOS');
            hasMainLine = true;
        }

        //Reajustar tarifas con el matrix para hacer cambios de adicionales <-> normal
        newMobiles = newMobiles.map(mobile => {
            let found = null;
            if(((mobile.is_main_line && mobile.rate_ids.refered != '')
                || (currentMaxMobile == null /*&& newMobiles.length == 1*/ && mobile.is_additional && mobile.rate_ids.refered != '' && mobile.is_main_line == true)
            )) {
                //cambiar la tarifa por la no reducida
                found = this.allRates.RATES.MOBILE.find(rateMobile => rateMobile.rate_ids.postpaid == mobile.rate_ids.refered
                    || rateMobile.rate_ids.prepaid == mobile.rate_ids.refered)
            }
            //Si no tenemos linea principal no cambiamos las normales a adicionales
            if(hasMainLine
                && mobile.is_main_line == false
                && mobile.rate_ids.additional != ''
                && (mobile.contract_type === mobileContractType.postpaid
                    || mobile.type_pay === mobileContractType.postpaid)) {
                //cambiar la tarifa a la reducida
                found = this.allRates.RATES.ADDITIONALS.find(rateMobile => rateMobile.rate_ids.postpaid == mobile.rate_ids.additional
                    || rateMobile.rate_ids.prepaid == mobile.rate_ids.additional)
            }

            return this.generateMobile(found, mobile);
        });

        return newMobiles;
    }

    getMaxFtthSpeed() {
        if(!this.rateFtth && !this.customerInfo) {
            return '0';
        }
        let speedOrder = this.rateFtth?.rate_info ? this.rateFtth.rate_info?.speed : '0';
        let maxSpeedCurrent = this.customerInfo?.max_broadband ? this.customerInfo.max_broadband.speed : '0'
        if(speedOrder == '1' || maxSpeedCurrent == '1') return '1';
        return +maxSpeedCurrent > +speedOrder ? maxSpeedCurrent : speedOrder;
    }

    getMainLine() {
        let mainLine = null;
        if(this.rateMobiles !== null ) {
            mainLine = this.rateMobiles.find(x=> x.is_main_line) ?? null;
        }
        if(mainLine == null) {
            let maxMobile = this.getMaxMobileCustomer();
            let maxSpeed = this.getMaxFtthSpeed();
            mainLine = maxMobile?.rate_info?.available_in.includes(`ftth_${maxSpeed}`) || this.getCustomer()?.has_package ? maxMobile : null
        }
        return mainLine;
    }

    getMainLineWithoutSpeed() {
        let mainLine = null;
        if(this.rateMobiles !== null ){
            mainLine = this.rateMobiles.find(x=> x.is_main_line) ?? null;
        }
        if(mainLine == null) {
            let maxMobile = this.getMaxMobileCustomer();
            mainLine = maxMobile ?? null
        }
        return mainLine;
    }

    getTotalMobileLines() {
        let newPostPaidMobiles = this.rateMobiles?.filter(x => x.type_pay === mobileContractType.postpaid);
        let countNewPospaidMobiles = newPostPaidMobiles ? newPostPaidMobiles.length : 0
        return countNewPospaidMobiles + (this.customerInfo?.max_mobile != null ? 1 : 0);
    }

    getIndexMainLine() {
        return this.rateMobiles.findIndex(x => x.is_main_line);
    }

    generateMobile(found, mobile) {
        let rateInfo = this.simyoGlobals.findByKeyInMobile(mobile, 'rate_info');
        if(found) {
            rateInfo = this.simyoGlobals.findByKeyInMobile(found, 'rate_info');
        }
        if(rateInfo == null) {
            return mobile;
        }
        let isAdditional = found ? found.is_additional : mobile.is_additional;
        let totalWithoutDiscount = isAdditional ? rateInfo.old_price : rateInfo.real_price;
        let discount = totalWithoutDiscount - rateInfo.price;

        let addFields = {
            is_main_line: mobile.is_main_line ?? false,
            type_pay:mobile.type_pay ? mobile.type_pay : mobile.contract_type,
            discount,
            total_without_discount: +totalWithoutDiscount,
            total_line: this.simyoGlobals.priceMobileMonth(found ? found : mobile),
            recharge: mobile.type_pay === mobileContractType.prepaid ? this.rateService.getRechargePrice(this.simyoGlobals.getMobilePrice(found ? found : mobile)) : null
        }

        if(!found) {
            return {
                ...mobile,
                ...addFields
            };
        }

        return {
            ...mobile,
            ...found,
            ...addFields
        };
    }

    searchMainLineInMatrix(mainLine) {
        if(mainLine == null) {
            return null;
        }
        return this.allRates.RATES.MOBILE.find(rate => rate.rate_info.product_code == mainLine?.rate_info?.product_code || rate.rate_info.product_code == mainLine?.rate_info?.product_code_refered);
    }

    getMaxBroadbandRate() {
        if(!this.rateFtth && !this.customerInfo) {
            return null;
        }
        let rate = null;
        let speedOrder = this.rateFtth?.rate_info ? this.rateFtth.rate_info?.speed : '0';
        let maxSpeedCurrent = this.customerInfo?.max_broadband ? this.customerInfo.max_broadband.speed : '0'
        if(maxSpeedCurrent == '1') rate = this.customerInfo?.max_broadband;
        if(speedOrder == '1') rate = this.rateFtth?.rate_info;
        rate = +maxSpeedCurrent > +speedOrder ? this.customerInfo?.max_broadband : this.rateFtth?.rate_info;

        if(rate.hasOwnProperty('type_normal') == false || rate.hasOwnProperty('type_discount') == false) {
            let rateMatrix = this.allRates.RATES.FTTH_NEW.find(r => r.type_discount.product_code == rate.rate_info.product_code || r.type_normal.product_code == rate.rate_info.product_code);
            if(rateMatrix != null) {
                rate.type_normal = rateMatrix.type_normal;
                rate.type_discount = rateMatrix.type_discount;
            }
        }

        return rate;
    }

    allowedRate18GBNormalPrice(onlyCustomer = false) {
        let mainLine = onlyCustomer ? this.getMaxMobileCustomer() : this.getMainLineWithoutSpeed();
        if(mainLine == null || mainLine.price > 7 || mainLine.total_line > 7 || this.customerHasPackage() == false) {
            return false;
        }
        return true;
    }
}
