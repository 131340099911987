import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PepephoneListadoActivacionesComponent} from './components/pepephone-listado-activaciones/pepephone-listado-activaciones.component';
import {PepephoneOrderDetailComponent} from './components/pepephone-order-detail/pepephone-order-detail.component';

const routes: Routes = [
    {path: 'listado', component: PepephoneListadoActivacionesComponent},
    {path: 'order/:order_id', component: PepephoneOrderDetailComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PepephoneRoutingModule {
}
