import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GuukRoutingModule } from './guuk-routing.module';
import { GuukListadoActivacionesComponent } from './components/guuk-listado-activaciones/guuk-listado-activaciones.component';
import { GuukOrderDetailComponent } from './components/guuk-order-detail/guuk-order-detail.component';
import { GuukSubheaderComponent } from './components/guuk-subheader/guuk-subheader.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';


@NgModule({
    declarations: [
        GuukListadoActivacionesComponent,
        GuukOrderDetailComponent,
        GuukSubheaderComponent
    ],
    exports: [
        GuukSubheaderComponent
    ],
    imports: [
        CommonModule,
        GuukRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class GuukModule { }
