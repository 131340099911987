export type Permission = {
    include: { [key: string]: string|boolean },
    notInclude: { [key: string]: string|boolean }
};

export type Brand = { [key: string]: Permission};
export type ListPermissions = { [key: string]: Brand};

/**
* ***** COMO AGREGAR UN NUEVO PERMISO *****
* 1. Tiene que estar contenido dentro de un padre (amena, rep_movil, simyo, jazztel, orange, others...)
* 2. El objeto a añadir tiene que tener la siguiente estructura
* <nombre_descriptivo_para_la_app>: {
*     include: {
*        <NOMBRE_PERMISO>: '<VALOR_QUE_LO_HAGA_VERDADERO>'
*     },
*     notInclude: {
*        <NOMBRE_PERMISO>: '<VALOR_QUE_LO_HAGA_VERDADERO>'
*     }
* }
* 3. En el apartado de include añadiremos los permisos que queramos compara con el operador ===.
* Por ejemplo. Si queremos comprobar que el usuario tiene prepago de orange añadiremos al include
*  PREPAGO_ORANGE: 'PERMITIR_ACTIVACIONES_DE_PREPAGO' para que la condición que se forme sea la siguiente
*  PREPAGO_ORANGE === PERMITIR_ACTIVACIONES_DE_PREPAGO
* 4. Si por el contrario lo que quermos comprobar es que sea distinto (!==) tendremos que añadir el permiso en el
* apartado de notInclude.
* Por ejemplo. VENDE_SIMYO: 'BLOQUEA_SIMYO' la condición resultante sería VENDE_SIMYO !== 'BLOQUEA_SIMYO
*
* 5. Ahora bien si necesitamos hacer condiciones más complejas en las que comprobar en el mismo permiso distintos
* valores podemos usar los operadores & y |
* Dejo Algunos ejemplos y su equivalencia en la condicion resultante
* Ejemplo 1.
* VENDE_SIMYO !== BLOQUEA_SIMYO && VENDE_SIMYO !== TUVO_ACTIVACIONES
* permite_vender: {
*     include: {},
*     notInclude: {
*         VENDE_SIMYO: 'BLOQUEA_SIMYO&TUVO_ACTIVACIONES'
*     }
* }
*
* Ejemplo 2.
* SIMYO_TIPO_FIRMA == DIGITAL || SIMYO_TIPO_FIRMA == TODAS
* permite_digital_signature: {
*   include: {
*       SIMYO_TIPO_FIRMA: 'DIGITAL|TODAS'
*   },
*   notInclude: {}
* }
*
* Ejemplo 3.
* SIMYO_CONVERGENCIA === PERMITIR_ALTAS_CONVERGENTES && VENDE_SIMYO !== BLOQUEA_SIMYO
* permite_vender_broadband: {
*    include: {
*        SIMYO_CONVERGENCIA: 'PERMITIR_ALTAS_CONVERGENTES'
*    },
*    notInclude: {
*        VENDE_SIMYO: 'BLOQUEA_SIMYO'
*    }
* }
*/
export const allPermissions: ListPermissions = {
    amena: {
        permite_activaciones: {
            include: {
                AMENA: 'PERMITIR_ACTIVACIONES_AMENA'
            },
            notInclude: {}
        },
        tuvo_activaciones: {
            include: {
                AMENA: 'TUVO_ACTIVACIONES'
            },
            notInclude: {}
        },
        bloquea_activaciones: {
            include: {
                AMENA: 'BLOQUEAR_ACTIVACIONES_AMENA'
            },
            notInclude: {}
        },
        patch_old_amena: {
            include: {
                PATCH_OLD_AMENA: 'PATCH_OLD_AMENA'
            },
            notInclude: {}
        },
        permite_ventas_convergentes: {
            include: {
                AMENA_CONVERGENTE: 'PERMITIR_ENVIO_AMENA_CONVERGENTE'
            },
            notInclude: {}
        }
    },
    rep_movil: {
        permite_ventas: {
            include: {
                REPUBLICA_MOVIL: 'PERMITE_VENTAS'
            },
            notInclude: {}
        },
        tuvo_activaciones: {
            include: {
                REPUBLICA_MOVIL: 'TUVO_ACTIVACIONES'
            },
            notInclude: {}
        },
        permite_ventas_convergente: {
            include: {
                REPUBLICA_MOVIL_CONVERGENCIA: 'PERMITIR_ALTAS_CONVERGENTES'
            },
            notInclude: {}
        },
        permite_duplicado_sim: {
            include: {
                REPUBLICA_MOVIL_DUPLICADO_DE_SIM: 'PERMITE_DUPLICADO_DE_SIM'
            },
            notInclude: {}
        },
    },
    simyo: {
        permite_ventas_viejo_frontal: {
            include: {
                VENDE_SIMYO: 'VENDE_SIMYO'
            },
            notInclude: {}
        },
        permite_ventas_nuevo_frontal: {
            include: {
                VENDE_SIMYO: 'VENDE_SIMYO_NEW'
            },
            notInclude: {}
        },
        bloquea_ventas: {
            include: {
                VENDE_SIMYO: 'BLOQUEA_SIMYO'
            },
            notInclude: {}
        },
        tuvo_activaciones: {
            include: {
                VENDE_SIMYO: 'TUVO_ACTIVACIONES'
            },
            notInclude: {}
        },
        demo: {
            include: {
                VENDE_SIMYO: 'VENDE_SIMYO_DEMO'
            },
            notInclude: {}
        },
        permite_ventas_convergente: {
            include: {
                SIMYO_CONVERGENCIA: 'PERMITIR_ALTAS_CONVERGENTES'
            },
            notInclude: {}
        },
        permite_configurador_tarifas: {
            include: {
                SIMYO_CONFIGURADOR_DE_TARIFAS: 'PERMITIR_CONFIGURADOR'
            },
            notInclude: {}
        },
        permite_ventas_cif: {
            include: {
                SIMYO_ACTIVACIONES_CON_CIF: 'PERMITIR_ACTIVACIONES_CON_CIF'
            },
            notInclude: {}
        },
        permite_venta_terminales: {
            include: {
                SIMYO_TERMINALES: 'PERMITIR_VENTA_TERMINALES'
            },
            notInclude: {}
        },
        permite_duplicado_sim: {
            include: {
                SIMYO_DUPLICADOS_DE_SIM: 'PERMITIR_DUPLICADOS'
            },
            notInclude: {}
        },
        nbss_block: {
            include: {
                SIMYO_NBSS_BLOCK: ''
            },
            notInclude: {}
        },
        permite_vender: { //VENDE_SIMYO !== BLOQUEA_SIMYO && VENDE_SIMYO !== TUVO_ACTIVACIONES
            include: {},
            notInclude: {
                VENDE_SIMYO: 'BLOQUEA_SIMYO&TUVO_ACTIVACIONES'
            }
        },
        permite_vender_broadband: {// SIMYO_CONVERGENCIA === PERMITIR_ALTAS_CONVERGENTES && VENDE_SIMYO !== BLOQUEA_SIMYO
            include: {
                SIMYO_CONVERGENCIA: 'PERMITIR_ALTAS_CONVERGENTES'
            },
            notInclude: {
                VENDE_SIMYO: 'BLOQUEA_SIMYO'
            }
        },
        permite_vender_cif: { // SIMYO_ACTIVACIONES_CON_CIF === PERMITIR_ACTIVACIONES_CON_CIF
            include: {
                SIMYO_ACTIVACIONES_CON_CIF: 'PERMITIR_ACTIVACIONES_CON_CIF'
            },
            notInclude: {}
        },
        permite_new_signature: {//SIMYO_TIPO_FIRMA == DIGITAL || SIMYO_TIPO_FIRMA == MANUAL || SIMYO_TIPO_FIRMA == TODAS
            include: {
                SIMYO_TIPO_FIRMA: 'DIGITAL|MANUAL|TODAS'
            },
            notInclude: {}
        },
        permite_digital_signature: {//SIMYO_TIPO_FIRMA == DIGITAL || SIMYO_TIPO_FIRMA == TODAS
            include: {
                SIMYO_TIPO_FIRMA: 'DIGITAL|TODAS'
            },
            notInclude: {}
        },
        permite_manual_signature: {//SIMYO_TIPO_FIRMA == MANUAL || SIMYO_TIPO_FIRMA == TODAS
            include: {
                SIMYO_TIPO_FIRMA: 'MANUAL|TODAS'
            },
            notInclude: {}
        },
        permite_sell_prepaid: {//SIMYO_MOVIL_PREPAGO_POSPAGO === VENDE_PREPAGO
            include: {
                SIMYO_MOVIL_PREPAGO_POSPAGO: 'VENDE_PREPAGO'
            },
            notInclude: {}
        },
        permite_sell_postpaid: { //SIMYO_MOVIL_PREPAGO_POSPAGO === VENDE_POSPAGO
            include: {
                SIMYO_MOVIL_PREPAGO_POSPAGO: 'VENDE_POSPAGO'
            },
            notInclude: {}
        },
        permite_sell_prepaid_postpaid: { //SIMYO_MOVIL_PREPAGO_POSPAGO === VENDE_PREPAGO_POSPAGO
            include: {
                SIMYO_MOVIL_PREPAGO_POSPAGO: 'VENDE_PREPAGO_POSPAGO'
            },
            notInclude: {}
        },
        permite_sell_esim: {
            include: {
                VENTA_ESIM_SIMYO: 'PERMITIR|PERMITIR_PERO_NO_DISPONIBLE'
            },
            notInclude: {}
        },
        disponible_sell_esim: {
            include: {
                VENTA_ESIM_SIMYO: 'PERMITIR'
            },
            notInclude: {}
        },
        permite_portabilidad_con_cambio_de_titular: {
            include: {
                PORTABILIDAD_CAMBIO_DE_TITULAR: 'PERMITIR'
            },
            notInclude: {}
        },
        permite_portabilidad_saltar_kyc: {
            include: {
                PORTABILIDAD_SALTAR_KYC: 'PERMITIR'
            },
            notInclude: {}
        },
        permite_iv: {
            include: {
                CONSULTAR_INSTALACION_VIRTUAL: 'PERMITIR'
            },
            notInclude: {}
        },
        permite_iv_dir: {
            include: {
                CONSULTAR_INSTALACION_VIRTUAL: 'PERMITIR|PERMITIR_SOLO_FIBRA_DIRECTA'
            },
            notInclude: {}
        },
        permite_iv_vula: {
            include: {
                CONSULTAR_INSTALACION_VIRTUAL: 'PERMITIR|PERMITIR_SOLO_FIBRA_VULA'
            },
            notInclude: {}
        },
        permite_cita_previa: {
            include: {
                CITACIONES_INSTALACION_FIBRA: 'PERMITIR'
            },
            notInclude: {}
        },
        pendiente_activacion: {
            include: {
                VENDE_SIMYO: 'VENDE_SIMYO_PENDIENTE_ACTIVACION'
            },
            notInclude: {}
        }
    },
    jazztel: {
        permite_ventas: { //VENDE_JAZZTEL === PERMITIR
            include: {
                VENDE_JAZZTEL: 'PERMITIR'
            },
            notInclude: {}
        },
        tuvo_activaciones: { //VENDE_JAZZTEL === TUVO_ACTIVACIONES
            include: {
                VENDE_JAZZTEL: 'TUVO_ACTIVACIONES'
            },
            notInclude: {}
        },
        demo: { //VENDE_JAZZTEL === DEMO
            include: {
                VENDE_JAZZTEL: 'DEMO'
            },
            notInclude: {}
        }
    },
    orange: {
        permite_ventas_prepago: {//PREPAGO_ORANGE === PERMITIR_ACTIVACIONES_DE_PREPAGO
            include: {
                PREPAGO_ORANGE: 'PERMITIR_ACTIVACIONES_DE_PREPAGO'
            },
            notInclude: {}
        },
        tuvo_activaciones_prepago: {// PREPAGO_ORANGE === TUVO_ACTIVACIONES
            include: {
                PREPAGO_ORANGE: 'TUVO_ACTIVACIONES'
            },
            notInclude: {}
        },
        permite_ventas_pospago: { // POSTPAGO === PERMITIR_VENTAS_POSTPAGO
            include: {
                POSTPAGO: 'PERMITIR_VENTAS_POSTPAGO'
            },
            notInclude: {}
        },
        tuvo_activaciones_pospago: { //POSTPAGO === TUVO_ACTIVACIONES
            include: {
                POSTPAGO: 'TUVO_ACTIVACIONES'
            },
            notInclude: {}
        },
        permite_portabilidad: {// PRODUCTOS_DE_PORTABILIDAD === PORTABILIDADES_ACTIVAS
            include: {
                PRODUCTOS_DE_PORTABILIDAD: 'PORTABILIDADES_ACTIVAS'
            },
            notInclude: {}
        },
        permite_venta_terminal_sim: { // PREPAGO_VENTA_TERMINAL_+_SIM === PERMITIR_ACCESO_A_VENTA_TERMINAL_
            include: {
                'PREPAGO_VENTA_TERMINAL_+_SIM': 'PERMITIR_ACCESO_A_VENTA_TERMINAL_+_SIM'
            },
            notInclude: {}
        },
        permite_renovacion_anticipada_bonos: {//RENOVACION_ANTICIPADA_BONOS === PERMITIR_RENOVACION_DE_BONOS_ANTICIPADA
            include: {
                RENOVACION_ANTICIPADA_BONOS: 'PERMITIR_RENOVACION_DE_BONOS_ANTICIPADA'
            },
            notInclude: {}
        },
        permite_recargas_durante_activacion: { // RECARGAS_DURANTE_ACTIVACION === PERMITE_RECARGA
            include: {
                RECARGAS_DURANTE_ACTIVACION: 'PERMITE_RECARGA'
            },
            notInclude: {}
        },
        nuevo_flujo_prepago_orange: { //NUEVO_FLUJO_PREPAGO_ORANGE === NUEVO_FLUJO_PREPAGO_ORANGE
            include: {
                NUEVO_FLUJO_PREPAGO_ORANGE: true
            },
            notInclude: {}
        },
        permite_sell_bonos_aditional: { //PREPAGO_ORANGE_ACTIVACION_BONOS === PERMITIR_ACTIVACION_BONOS
            include: {
                PREPAGO_ORANGE_ACTIVACION_BONOS: 'PERMITIR_ACTIVACION_BONOS'
            },
            notInclude: {}
        },
        permite_prepaid_activate_multiple: {// PREPAGO_ACTIVACIONES_MULTIPLES === PERMITIR_ACTIVACIONES_MULTIPLES
            include: {
                PREPAGO_ACTIVACIONES_MULTIPLES: 'PERMITIR_ACTIVACIONES_MULTIPLES'
            },
            notInclude: {}
        },
        permite_sell_esim: { // ESIM === PERMITE_VENTA_ESIM_Y_PREPAGO || ESIM === PERMITE_VENTA_SOLO_ESIM
            include: {
                ESIM: 'PERMITE_VENTA_ESIM_Y_PREPAGO|PERMITE_VENTA_SOLO_ESIM'
            },
            notInclude: {}
        },
        permite_sell_only_esim: { // ESIM === PERMITE_VENTA_SOLO_ESIM
            include: {
                ESIM: 'PERMITE_VENTA_SOLO_ESIM'
            },
            notInclude: {}
        },
        permite_view_rates_mundo_esim: { // ESIM_VENTA_TARIFAS_MUNDO === MUESTRA_TARIFAS_MUNDO_EN_ESIM
            include: {
                ESIM_VENTA_TARIFAS_MUNDO: 'MUESTRA_TARIFAS_MUNDO_EN_ESIM'
            },
            notInclude: {}
        },
        permite_view_kyc_esim: {// ESIM_KYC_ESIM_PREPAGO === MUESTRA_KYC_EN_ESIM
            include: {
                ESIM_KYC_ESIM_PREPAGO: 'MUESTRA_KYC_EN_ESIM'
            },
            notInclude: {}
        },
        permite_view_new_esim: {// ESIM_MOSTRAR_NUEVO_DESARROLLO === MUESTRA_NUEVO_DESARROLLO_ESIM
            include: {
                ESIM_MOSTRAR_NUEVO_DESARROLLO: 'MUESTRA_NUEVO_DESARROLLO_ESIM'
            },
            notInclude: {}
        }

    },
    guuk: {
        vende_guuk: {
            include: {
                VENDE_GUUK: 'PERMITIR'
            },
            notInclude: {}
        }
    },
    masmovil: {
        vende_masmovil: {
            include: {
                VENDE_MASMOVIL: 'PERMITIR'
            },
            notInclude: {}
        }
    },
    pepephone: {
        vende_pepephone: {
            include: {
                VENDE_PEPEPHONE: 'PERMITIR'
            },
            notInclude: {}
        }
    },
    others: {
        users_demo: { // SAMPLE_MODE === SAMPLE
            include: {
                SAMPLE_MODE: 'SAMPLE'
            },
            notInclude: {}
        },
        terminales_libres: { // TERMINALES_LIBRES === PERMITE_TERMINALES_LIBRES
            include: {
                TERMINALES_LIBRES: 'PERMITE_TERMINALES_LIBRES'
            },
            notInclude: {}
        },
        recharges_permite_saldo_promocional: { //RECARGAS_DE_SALDO_PROMOCIONAL === PERMITIR_RECARGAS_SI_SE_HA_ASIGNADO_SALDO
            include: {
                RECARGAS_DE_SALDO_PROMOCIONAL: 'PERMITIR_RECARGAS_SI_SE_HA_ASIGNADO_SALDO'
            },
            notInclude: {}
        },
        recharges_bloquea_saldo_dexga: { // RECARGAS_DE_SALDO_DEXGA === BLOQUEA_RECARGAS
            include: {
                RECARGAS_DE_SALDO_DEXGA: 'BLOQUEA_RECARGAS'
            },
            notInclude: {}
        },
        comissions_permitir: { // COMISIONES === PERMITIR_COMISIONES
            include: {
                COMISIONES: 'PERMITIR_COMISIONES'
            },
            notInclude: {}
        },
        comissions_pend_doc: { // COMISIONES === PENDIENTE_DOCUMENTACION
            include: {
                COMISIONES: 'PENDIENTE_DOCUMENTACION'
            },
            notInclude: {}
        },
        comissions_pend_verificacion: {// COMISIONES === PENDIENTE_VERIFICACION
            include: {
                COMISIONES: 'PENDIENTE_VERIFICACION'
            },
            notInclude: {}
        },
        access_clico: { //ACCESO_A_CLICO === PERMITIR_ACCESO_A_CLICO
            include: {
                ACCESO_A_CLICO: 'PERMITIR_ACCESO_A_CLICO'
            },
            notInclude: {}
        },
        access_points: { // MOSTRAR_PUNTOS === MUESTRA_PUNTOS
            include: {
                PROGRAMA_ATREVETE: 'ACTIVADO'
            },
            notInclude: {}
        },
        access_night: {
            include: {
                LIMITAR_OPERATIVA_NOCTURNO: 'NORMAL'
            },
            notInclude: {}
        }

    }
}
