import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultadoComponent } from './components/resultado/resultado.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconsModule } from 'src/app/icons/icons.module';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomHeader } from './components/datepicker-custom-header/datepicker-custom-header.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { UserNotFoundComponent } from './components/user-not-found/user-not-found.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DatepickerCustomComponent } from './components/datepicker-custom/datepicker-custom.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BlockPasteDirective } from './directives/block-paste.directive';
import { ToFixedDecimalsPipe } from './pipes/to-fixed-decimals.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

export const MY_FORMATS = {
    parse: {dateInput: 'DD/MM/YYYY'},
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@NgModule({
    declarations: [
        ResultadoComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
        CustomHeader,
        UserNotFoundComponent,
        OrderByPipe,
        DatepickerCustomComponent,
        BlockPasteDirective,
        ToFixedDecimalsPipe,
        TruncatePipe
    ],
    exports: [
        // Components
        ResultadoComponent,
        ConfirmDialogComponent,
        CustomHeader,
        UserNotFoundComponent,
        // Modules
        FormsModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatGoogleMapsAutocompleteModule,
        NgbModule,
        MatAutocompleteModule,
        FontAwesomeModule,
        NgxMaskModule,
        IconsModule,
        MatIconModule,
        MatRadioModule,
        MatProgressBarModule,
        // Pipes
        OrderByPipe,
        ToFixedDecimalsPipe,
        DatepickerCustomComponent,
        MatSlideToggleModule,
        BlockPasteDirective,
        TruncatePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatGoogleMapsAutocompleteModule,
        NgbModule,
        MatAutocompleteModule,
        FontAwesomeModule,
        IconsModule,
        MatIconModule
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        NgbActiveModal
    ]
})
export class SharedModule { }
