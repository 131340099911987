/* Modulos */
import { RepublicaMovilModule } from './republica-movil/republica-movil.module';
import { HomeModule} from './home/home.module';
import { SimyoModule } from './simyo/simyo.module';
import { IconsModule } from './icons/icons.module';
import { OrangeModule } from './orange/orange.module';
import { CoreModule } from './core/core.module';
/* Modulos material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepper} from '@angular/material/stepper';
/* Componentes */
import { AppComponent} from './app.component';
/* Servicios */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
/* Redux */
import { StoreModule } from '@ngrx/store';
import { reducers } from './orange/redux';
import {HttpErrorInterceptor} from './core/injectables/http-error.interceptor';

import * as moment from 'moment';
import 'moment/locale/es';
import {MasmovilModule} from './masmovil/masmovil.module';
import {PepephoneModule} from './pepephone/pepephone.module';
import {GuukModule} from './guuk/guuk.module';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        RepublicaMovilModule,
        SimyoModule,
        OrangeModule,
        HomeModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IconsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBUT7T6HqQJnWYKy3pjPcBRYj-4Mmsd1ao',
            libraries: ['places']
        }),
        NgxMaskModule.forRoot(maskConfig),
        StoreModule.forRoot(
            reducers
        ),
        CoreModule,
        MasmovilModule,
        PepephoneModule,
        GuukModule
    ],
    providers: [
        MatStepper,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        }
        // NgbActiveModal
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor() {
        // Establece el idioma global de moment a español
        moment.locale('es');
      }
}
