<div class="container content">
    <ng-container *ngIf="(orange$ | async)?.master?.rates && (orange$ | async)?.master?.rates.length">
        <div class="row mb-4">
            <div class="col-lg-12 mb-2">
                <h5 class="title-step" align="center">
                    <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20"> {{ getTitle() }}
                </h5>
            </div>
            <div class="col-lg-6" id="allConfRates">
                <div class="btn card bg-white configurador py-20 rates-box-height" [class.opacity-50]="isCollapsedWelcomePack && !isCollapsedRates" [class.card-highlight]="!isCollapsedWelcomePack">
                    <h5 class="font-weight-bold rates-box mb-0">
                        <ng-container *ngIf="(orange$ | async)?.welcome_pack_selected">
                            <img class="img-fluid mr-3" [src]="(orange$ | async)?.welcome_pack_selected?.img && (orange$ | async)?.welcome_pack_selected?.img !== '' ? (orange$ | async)?.welcome_pack_selected?.img : 'https://via.placeholder.com/60x60'" width="70" style="height: 70px">
                              {{ (orange$ | async)?.welcome_pack_selected.name }}
                        </ng-container>
                        <ng-container *ngIf="!(orange$ | async)?.welcome_pack_selected">
                            Seleccione el welcome pack
                        </ng-container>
                    </h5>
                    <ng-container *ngIf="(orange$ | async)?.master?.rates.length > 1">
                        <small (click)="changeStateCollapseWelcomePack()" class="text-muted rates-box-edit" [attr.aria-expanded]="!isCollapsedWelcomePack" aria-controls="collapseWelcomePack" *ngIf="(orange$ | async)?.welcome_pack_selected">
                            <img src="./assets/img/edit.svg" class="img-fluid" width="20">
                            <!--<span class="btn btn-black-outline btn-sm">
                                &lt;!&ndash;<b *ngIf="!(orange$ | async)?.welcome_pack_selected">Seleccionar welcome pack</b>&ndash;&gt;
                                <b>Modificar selección</b>
                            </span>-->
                        </small>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="btn card bg-white configurador py-20 rates-box-height" (click)="isWelcomePackSelected()" [class.opacity-50]="isCollapsedRates && !isCollapsedWelcomePack" [class.card-highlight]="!isCollapsedRates">
                    <h5 class="font-weight-bold rates-box mb-0">
                        <ng-container *ngIf="(orange$ | async)?.rate_selected as rateSelected">
                            <span class="badge badge-secondary mr-2">Tarifa</span> <span [innerHTML]="(orange$ | async)?.rate_selected.name"></span> <i-feather (click)="openModal(infoBono, rateSelected)" name="alert-circle" class="icon-info ml-1 cursor-pointer" style="position: relative; bottom: 5px;"></i-feather>
                        </ng-container>
                        <ng-container *ngIf="!(orange$ | async)?.rate_selected">
                            Seleccione la tarifa
                        </ng-container>
                    </h5>
                    <div class="pt-3" *ngIf="showHaveToSelectWP">
                        Debe seleccionar un welcome pack
                    </div>
                    <ng-container *ngIf="(orange$ | async)?.welcome_pack_selected && (orange$ | async)?.welcome_pack_selected.rates.length > 1 && (orange$ | async)?.rate_selected">
                        <small (click)="changeStateCollapseRates()" class="text-muted rates-box-edit" [attr.aria-expanded]="!isCollapsedRates" aria-controls="collapseRates">
                            <img src="./assets/img/edit.svg" class="img-fluid" width="20">
                            <!--<span class="btn btn-black-outline btn-sm">
                                &lt;!&ndash;<b *ngIf="!(orange$ | async)?.rate_selected">Seleccionar tarifa</b>&ndash;&gt;
                                <b>Modificar selección</b>
                            </span>-->
                        </small>
                    </ng-container>
                </div>
            </div>

            <div class="col-12" [(ngbCollapse)]="isCollapsedWelcomePack" id="collapsedWelcomePack">
                <div class="card card-body mb-3 bg-white card-highlight-content welcome-pack">
                    <div class="row justify-content-center">
                        <div class="col-lg-2 py-3" *ngFor="let welcomePack of (orange$ | async)?.master?.rates">
                            <div class="link-orange">
                                <div class="card-tar card-white mb-5 mb-lg-0">
                                    <div class="card-tar-body-1 cursor-pointer" (click)="setWelcomePack(welcomePack)">
                                        <img [src]="welcomePack.img && welcomePack.img !== '' ? welcomePack.img : 'https://via.placeholder.com/300x300'" width="300" class="img-fluid mb-2 border">
                                        <!-- Servicios contratados -->
                                        <div align="center" class="tar-simyo-des font-weight-bold">
                                            {{ welcomePack?.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" [(ngbCollapse)]="isCollapsedRates" id="collapsedRates" *ngIf="(orange$ | async)?.welcome_pack_selected?.rates?.length">
                <div class="card card-body mb-3 bg-white card-highlight-content rates">
                    <div class="row justify-content-center">
                        <div *ngIf="hayPromo(filterRates((orange$ | async)?.welcome_pack_selected)) && showTabsEsim((orange$ | async)) === false" class="promo-card">
                            <div class="promo">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="promo-title"><span [innerHTML]="promo"></span></td>
                                            <td class="promo-desc"><span [innerHTML]="promo_desc"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12 row mb-3 esim-selected-rates" *ngIf="showTabsEsim((orange$ | async))">
                            <div class="col-12 row card-header">
                                <div [class]="'btn rates-box-height rate-subtype-box px-2 ' + setClassCssIfSelectedTypeRates('orange prepago')" (click)="changeRateEsimSelected('orange prepago')">
                                    <div class="card bg-white">
                                        <h6 class="font-weight-bold rates-box mb-0">Orange Prepago</h6>
                                    </div>
                                </div>
                                <div [class]="'btn rates-box-height rate-subtype-box px-2 ' + setClassCssIfSelectedTypeRates('holiday')" (click)="changeRateEsimSelected('holiday')">
                                    <div class="card bg-white">
                                        <h6 class="font-weight-bold rates-box mb-0">Tarifas Holiday</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="hayPromo(filterRates((orange$ | async)?.welcome_pack_selected)) && showTabsEsim((orange$ | async)) === true" class="promo-card">
                            <div class="promo">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="promo-title"><span [innerHTML]="promo"></span></td>
                                            <td class="promo-desc"><span [innerHTML]="promo_desc"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ng-container *ngFor="let rate of filterRates((orange$ | async)?.welcome_pack_selected);let i = index; let count = count">
                            <div class="w-100" *ngIf="count == 5 && i == 3"></div>
                            <div class="col-lg-3 py-3 hvr-float" >
                                <div class="card-tar card-white mb-5 mb-lg-0 ribbon-parent" style="height: 100%">
                                    <ng-template #promoTip>{{rate.promo}}</ng-template>
                                    <span [ngbTooltip]="promoTip" placement="right" *ngIf="rate.promo" class="ribbon left">PROMO</span>
                                    <div class="card-orange-rate-header">
                                        <p align="center" class="tar-simyo-tar flex mb-0">
                                            <b [innerHTML]="rate.name"></b>
                                            <i-feather (click)="openModal(infoBono, rate)" name="alert-circle" class="icon-info ml-1 cursor-pointer"></i-feather>
                                        </p>
                                    </div>
                                    <div class="card-tar-body d-flex flex-column justify-content-end">
                                        <!-- Servicios contratados -->
                                        <div class="text-center" *ngIf="rate.subtitle && rate.subtitle !== ''">
                                            <p class="badge badge-pill badge-orange font-list">{{ rate.subtitle }}</p>
                                        </div>
                                        <!-- Servicios contratados -->
                                        <p align="center" class="tar-simyo-des font-weight-bold mb-4 font-size-lg text-orange">
                                            {{ rate.price ? (rate.price + '€') : '0€' }}
                                        </p>
                                        <!-- Contratar -->
                                        <button id="tarifas-prepago2" class="btn btn-simyo2 btn-block btn-sm mb-2" (click)="setRate(rate)">
                                            Seleccionar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

        <div class="row my-2" [formGroup]="configurationFormGroup">
            <div class="col-xl-12" *ngIf="(orange$ | async)?.rate_selected && (orange$ | async)?.welcome_pack_selected?.is_portability">
                <div class="card bg-white configurador my-3 pb-3" style="min-height: 230px;">
                    <h6 class="font-weight-bold">
                        Indica el número de teléfono de la <span class="text-simyo">SIM</span> y los datos de la <span class="text-simyo">SIM</span>

                        <div #formRow class="form-row">
                            <mat-form-field class="col-6" *ngFor="let msisdn of configurationFormGroup?.controls?.msisdns?.controls">
                                <mat-label>Número de teléfono que tiene el cliente</mat-label>
                                <input matInput [formControl]="msisdn" type="text" class="iban" maxlength="9" (keypress)="numberOnly($event)">
                                <i class="bar"></i>
                                <!-- Mensajes de error -->
                                <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'required')">
                                    Campo obligatorio
                                </mat-error>
                                <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'minlength')">
                                    El número de teléfono debe tener 9 dígitos
                                </mat-error>
                                <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'pattern') && !errorMsisdnsHandling(msisdn, 'minlength')">
                                    El número de teléfono debe empezar por 6 o 7 y sólo números
                                </mat-error>
                                <mat-error *ngIf="errorHandling('msisdns', 'duplicate')">
                                    El número de teléfono no se puede repetir
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Operador donante</mat-label>
                                <select matNativeControl formControlName="portability_operator">
                                    <option *ngFor="let operator of operatorsService.getOperators()" [value]="operator.value">{{operator.title}}</option>
                                </select>
                                <i class="bar"></i>
                                <mat-error *ngIf="errorHandling('portability_operator', 'required')">
                                    Campo obligatorio
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Tipo de cliente donante</mat-label>
                                <select matNativeControl formControlName="portability_type_client">
                                    <option value="">Seleccione modo de pago</option>
                                    <option value="T">Prepago</option>
                                    <option value="C">Contrato</option>
                                </select>
                                <i class="bar"></i>
                                <mat-error *ngIf="errorHandling('portability_type_client', 'required')">
                                    Campo obligatorio
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6 no-float-label" [floatLabel]="floatLabelControl.value">
                                <h6>Tarjeta SIM actual <i-feather (click)="openModalInfo(simPortabilidadOrigen)" name="alert-circle" class="icon-info ml-1 cursor-pointer"></i-feather></h6>
                                <input matInput formControlName="portability_old_iccid" type="text" class="iban" placeholder="Número tarjeta SIM que el cliente tiene con su operador actual" maxlength="19" (keypress)="numberOnly($event)">
                                <i class="bar"></i>

                                <mat-error *ngIf="errorHandling('portability_old_iccid', 'required')">
                                    Campo obligatorio
                                </mat-error>
                                <mat-error *ngIf="errorHandling('portability_old_iccid', 'minlength')">
                                    El número de IMEI debe tener 19 dígitos
                                </mat-error>
                                <mat-error *ngIf="errorHandling('portability_old_iccid', 'duplicate') && !errorHandling('portability_old_iccid', 'required')">
                                    El valor no puede ser el mismo que la nueva tarjeta sim
                                </mat-error>
                                <mat-error *ngIf="errorHandling('portability_old_iccid', 'pattern') && !errorHandling('terminal_imei', 'minlength')">
                                    El número de IMEI debe ser sólo números
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-6 no-float-label" [floatLabel]="floatLabelControl.value">
                                <h6>Nueva tarjeta SIM <i-feather (click)="openModalInfo(simPortabilidad)" name="alert-circle" class="icon-info ml-1 cursor-pointer"></i-feather></h6>
                                <input matInput formControlName="portability_new_iccid" type="text" class="iban" placeholder="Número de tarjeta SIM que vas a entregar al cliente" maxlength="19" (keypress)="numberOnly($event)">
                                <i class="bar"></i>

                                <mat-error *ngIf="errorHandling('portability_new_iccid', 'required')">
                                    Campo obligatorio
                                </mat-error>
                                <mat-error *ngIf="errorHandling('portability_new_iccid', 'minlength')">
                                    El número de IMEI debe tener 19 dígitos
                                </mat-error>
                                <mat-error *ngIf="errorHandling('portability_new_iccid', 'duplicate') && !errorHandling('portability_new_iccid', 'required')">
                                    El valor no puede ser el mismo que la tarjeta sim actual
                                </mat-error>
                                <mat-error *ngIf="errorHandling('portability_new_iccid', 'pattern') && !errorHandling('terminal_imei', 'minlength')">
                                    El número de IMEI debe ser sólo números
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </h6>
                </div>
            </div>

            <div [class]="((orange$ | async)?.welcome_pack_selected?.is_prepaid && permSv.hasPermFromV2(allPermissions.orange.permite_recargas_durante_activacion) && !permSv.hasPermFromV2(allPermissions.orange.permite_prepaid_activate_multiple)) ? 'col-xl-6' : 'col-xl-12'"
                 *ngIf="(orange$ | async)?.rate_selected && ((orange$ | async)?.welcome_pack_selected?.is_prepaid || (orange$ | async)?.welcome_pack_selected?.is_terminal) && !(orange$ | async)?.welcome_pack_selected?.is_esim">
            <!--<div [class]="((orange$ | async)?.welcome_pack_selected?.is_prepaid && false) ? 'col-xl-6' : 'col-xl-12'"
                 *ngIf="(orange$ | async)?.rate_selected && ((orange$ | async)?.welcome_pack_selected?.is_prepaid || (orange$ | async)?.welcome_pack_selected?.is_terminal) && !(orange$ | async)?.welcome_pack_selected?.is_esim">-->
                <div class="card bg-white configurador my-3 pb-3">
                    <h6 class="font-weight-bold">
                        Indica el número de teléfono de la<span class="text-simyo"> SIM</span>
                    </h6>
                    <div #formRow class="form-row" *ngFor="let msisdn of configurationFormGroup?.controls?.msisdns?.controls">
                        <mat-form-field class="col-12">
                            <mat-label>Número Tarjeta SIM</mat-label>
                            <input matInput [formControl]="msisdn" type="text" class="iban" maxlength="9" (keypress)="numberOnly($event)" (input)="checkMsisdnValidity(msisdn)" (focusout)="errorMsisdnsHandling(msisdn, 'required')">
                            <i class="bar"></i>
                            <!-- Mensajes de error -->
                            <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'required')">
                                Campo obligatorio
                            </mat-error>
                            <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'minlength')">
                                El número de teléfono debe tener 9 dígitos
                            </mat-error>
                            <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'pattern') && !errorMsisdnsHandling(msisdn, 'minlength')">
                                El número de teléfono debe empezar por 6 o 7 y sólo números
                            </mat-error>
                            <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'duplicate')">
                                El número de teléfono no se puede repetir
                            </mat-error>
                            <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'used')">
                                MSISDN con una petición en curso
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="col-xl-6" *ngIf="(orange$ | async)?.rate_selected && (orange$ | async)?.welcome_pack_selected?.is_terminal">
                <div class="card bg-white configurador my-3 pb-3">
                    <h6 class="font-weight-bold">
                        Indica el IMEI del terminal
                    </h6>
                    <div class="form-row">
                        <mat-form-field class="col-12 no-float-label" [floatLabel]="floatLabelControl.value">
                            <input matInput formControlName="terminal_imei" type="text" class="iban" placeholder="XXXXXXXXXXXXXXX" maxlength="15" (keypress)="numberOnly($event)">
                            <i class="bar"></i>

                            <mat-error *ngIf="errorHandling('terminal_imei', 'required')">
                                Campo obligatorio
                            </mat-error>
                            <mat-error *ngIf="errorHandling('terminal_imei', 'minlength')">
                                El número de IMEI debe tener 15 dígitos
                            </mat-error>
                            <mat-error *ngIf="errorHandling('terminal_imei', 'pattern') && !errorHandling('terminal_imei', 'minlength')">
                                El número de IMEI debe ser sólo números
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div [ngClass]="{ 'col-xl-6': (orange$ | async)?.welcome_pack_selected?.is_prepaid, 'col-xl-12': (orange$ | async)?.welcome_pack_selected?.is_esim }"
                *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && (orange$ | async)?.rate_selected && permSv.hasPermFromV2(allPermissions.orange.permite_recargas_durante_activacion) && !permSv.hasPermFromV2(allPermissions.orange.permite_prepaid_activate_multiple) && ((orange$ | async)?.welcome_pack_selected?.is_prepaid || (orange$ | async)?.welcome_pack_selected?.is_esim)">
                <div class="card bg-white configurador mt-3 px-0" style="min-height: 230px;">
                    <h6 style="position: relative; left: 0px;" class="mb-4 font-weight-bold px-4">
                        ¿Quieres hacer una recarga?
                    </h6>
                    <div class="card-tar card-white mb-lg-0 px-4">
                        <div class="card-simyo-header mb-3 py-3">
                            <div class="d-flex justify-content-between align-items-center mx-3">
                                <span>
                                    <img src="./assets/img/saldo.svg" class="img-fluid font" width="13">
                                    <b *ngIf="(+pdv.info_recargas.saldo_promo + +pdv.info_recargas.saldo_dexga) == 0">
                                        No tiene saldo
                                    </b>
                                    <b *ngIf="(+pdv.info_recargas.saldo_promo + +pdv.info_recargas.saldo_dexga) > 0">
                                        Saldo: {{ (+pdv.info_recargas.saldo_promo + +pdv.info_recargas.saldo_dexga).toFixed(2) }} €
                                    </b>
                                </span>
                                <a *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0" href="{{ baseUrl }}recargas_dexga_gestion.itx" target="_blank" class="text-muted">
                                    <span class="btn btn-black-outline btn-sm">
                                        <b>Quiero comprar saldo</b>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="radio-btn-group mt-2 mb-4 px-4" style="align-self: center;">
                        <div class="radio" *ngFor="let recharge of orangeRefillAmounts; trackBy: trackItem" style="position: relative;">
                            <img class="img-fluid ico-title arrow-top cursor-pointer" (click)="changeRechargeAmmout(recharge, 'add')" *ngIf="+recharge.amount < MAX_MANUAL_AMOUNT && !recharge.disabled && recharge.id === 'custom' && (+pdv.info_recargas.saldo_promo + +pdv.info_recargas.saldo_dexga) >= (+recharge.amount + 5)" width="18" src="./assets/img/keyboard_arrow_up_black_24dp.svg">
                            <img class="img-fluid ico-title arrow-bottom cursor-pointer" (click)="changeRechargeAmmout(recharge, 'remove')" *ngIf="+recharge.amount > MIN_MANUAL_AMOUNT && !recharge.disabled && recharge.id === 'custom'" width="18" src="./assets/img/keyboard_arrow_down_black_24dp.svg">
                            <input [id]="recharge.id" type="radio" [value]="recharge.amount" formControlName="balance" (click)="changeAmount(recharge)">
                            <label [for]="recharge.id" class="font-weight-bold" [ngClass]="{'disabled-recharge': recharge.disabled}">
                                {{ recharge.amount === 0 ? 'Sin recarga' : recharge.amount + '€' }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="(pdv?.info?.username === 'demo_pdv' || pdv?.info?.username === 'demo_ase' || pdv?.info?.username === 'demo_ent1' || pdv?.info?.username === 'demo_ent2')
            && (orange$ | async)?.welcome_pack_selected?.is_esim && (orange$ | async)?.rate_selected && this.permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim)">
                <div class="col-lg-12 mt-2">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <!----- Tipo de operación ------->
                                <div class="radio-btn-group" ngbRadioGroup formControlName="type_operation"
                                    (change)="changeTypeOperation($event.target.value)">
                                    <h6 class="subtitulo-pack">Tipo de operación: </h6>

                                    <div class="radio radio-pack">
                                        <label ngbButtonLabel>
                                            <input ngbButton type="radio" [value]="eSimConstants.registrationTypeOperation"/>
                                            Alta nueva
                                        </label>
                                    </div>

                                    <div class="radio radio-pack" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)">
                                        <label ngbButtonLabel>
                                            <input ngbButton type="radio" [value]="eSimConstants.portabilityTypeOperation"/>
                                            Portabilidad
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <mat-error *ngIf="errorTypeOperationHandling('type_operation', 'required')">
                                Campo obligatorio
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12" *ngIf="(pdv?.info?.username === 'demo_pdv' || pdv?.info?.username === 'demo_ase' || pdv?.info?.username === 'demo_ent1' || pdv?.info?.username === 'demo_ent2')
                 && permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad) && configurationFormGroup.controls['type_operation'].value === 'portability'">
                    <div class="card bg-white configurador mb-3 pb-3" style="min-height: 160px;">
                        <h6 class="font-weight-bold">
                            Indica el número de teléfono de la <span class="text-simyo">SIM</span> y los datos de la <span class="text-simyo">SIM</span> del cliente

                            <div #formRow class="form-row mt-2">
                                <mat-form-field class="col-3" *ngFor="let msisdn of configurationFormGroup?.controls?.msisdns?.controls">
                                    <mat-label>Número de teléfono</mat-label>
                                    <input matInput [formControl]="msisdn" type="text" class="iban" maxlength="9" (keypress)="numberOnly($event)">
                                    <i class="bar"></i>
                                    <!-- Mensajes de error -->
                                    <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'required')">
                                        Campo obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'minlength')">
                                        El número de teléfono debe tener 9 dígitos
                                    </mat-error>
                                    <mat-error *ngIf="errorMsisdnsHandling(msisdn, 'pattern') && !errorMsisdnsHandling(msisdn, 'minlength')">
                                        El número de teléfono debe empezar por 6 o 7 y sólo números
                                    </mat-error>
                                    <mat-error *ngIf="errorHandling('msisdns', 'duplicate')">
                                        El número de teléfono no se puede repetir
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Operador donante</mat-label>
                                    <select matNativeControl formControlName="portability_operator" style="height: 49px;">
                                        <option *ngFor="let operator of operatorsService.getOperators()" [value]="operator.value">{{operator.title}}</option>
                                    </select>
                                    <i class="bar"></i>
                                    <mat-error *ngIf="errorHandling('portability_operator', 'required')">
                                        Campo obligatorio
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Tipo de cliente donante</mat-label>
                                    <select matNativeControl formControlName="portability_type_client"  style="height: 49px;">
                                        <option value="">Seleccione modo de pago</option>
                                        <option value="T">Prepago</option>
                                        <option value="C">Contrato</option>
                                    </select>
                                    <i class="bar"></i>
                                    <mat-error *ngIf="errorHandling('portability_type_client', 'required')">
                                        Campo obligatorio
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Número tarjeta SIM actual </mat-label>
                                    <input matInput formControlName="portability_old_iccid" type="text" class="iban" maxlength="19" (keypress)="numberOnly($event)">
                                    <i class="bar"></i>
                                    <mat-icon matSuffix><i-feather (click)="openModalInfo(simPortabilidadOrigen)" name="alert-circle" class="icon-info ml-1 cursor-pointer"></i-feather></mat-icon>
                                    <mat-error *ngIf="errorHandling('portability_old_iccid', 'required')">
                                        Campo obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="errorHandling('portability_old_iccid', 'minlength')">
                                        El número de IMEI debe tener 19 dígitos
                                    </mat-error>
                                    <mat-error *ngIf="errorHandling('portability_old_iccid', 'duplicate') && !errorHandling('portability_old_iccid', 'required')">
                                        El valor no puede ser el mismo que la nueva tarjeta sim
                                    </mat-error>
                                    <mat-error *ngIf="errorHandling('portability_old_iccid', 'pattern') && !errorHandling('terminal_imei', 'minlength')">
                                        El número de IMEI debe ser sólo números
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </h6>
                    </div>
                </div>
                <div class="col-xl-12" *ngIf="configurationFormGroup.controls['type_operation'].value === 'registration' && this.permSv.hasPermFromV2(allPermissions.orange.permite_prepaid_activate_multiple)">
                    <div class="card bg-white configurador mb-3 pb-3" style="min-height: 160px;">
                        <h6 class="font-weight-bold">Indica el número de líneas <span class="text-simyo">eSIM</span>.</h6>
                        <div class="form-row algin-items-center mt-2">
                            <mat-radio-group
                                formControlName="esim_number_lines" (change)="esimNumberLinesChange($event)">
                                <mat-radio-button class="px-3" *ngFor="let line of [1,2,3,4]" [value]="line">
                                    {{line}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </ng-container>


        </div>

        <!-- Continuar proceso -->
        <div align="center" *ngIf="configurationFormGroup.valid">
            <br>
            <button class="btn btn-black btn-block mb-1 pt-3 font-weight-bold" style="padding-bottom: 20px !important; width: 100% !important;" (click)="next()" role="button">
                Continuar <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
            </button>
            <br><br>
        </div>
    </ng-container>

    <div *ngIf="(orange$ | async)?.loadingMaster">
        <h5 align="center" class="tar-name-preload">Cargando tarifas prepago...</h5>
        <div align="center">
            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
        </div>
    </div>
    <div *ngIf="(orange$ | async)?.errorMaster">
        <h5 align="center" class="tar-name-preload">No hemos podido obtener las tarifas. Por favor, inténtelo de nuevo más tarde</h5>
    </div>
</div>

<ng-template #infoBono let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title white" id="exampleModalLabel">Información sobre el bono</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body m-3">
        <div class="row">
            <div class="col-lg-12">
                <h6 [innerHTML]='moreInfoRate.description'></h6>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #simPortabilidad let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title white" id="portabilityModalLabel">Número de tarjeta de portabilidad Orange</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body m-3">
        <div class="row">
            <div class="col-lg-12">
                <p>Encontrarás el número impreso en una etiqueta en de la tarjeta que vas a entregar al cliente. Es un número de 19 dígitos que comienza por 863401...</p>
                <img src="./assets/img/sim_portabilidad.gif" class="img-fluid">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #simPortabilidadOrigen let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title white" id="portabilityOriginModalLabel">Número de tarjeta SIM que el cliente tiene con su operador actual</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body m-3">
        <div class="row">
            <div class="col-lg-12">
                <p>Lo encontrarás impreso en la tarjeta SIM que el cliente usa dentro de su teléfono. Es un número de 19 dígitos (sólo números) que encontrarás impreso en la tarjeta.</p>
                <img src="./assets/img/sim_portabilidad_origen.gif" class="img-fluid">
            </div>
        </div>
    </div>
</ng-template>
