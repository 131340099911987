import {Component, HostListener, OnInit, ViewChildren} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-guuk-subheader',
    templateUrl: './guuk-subheader.component.html',
    styleUrls: ['./guuk-subheader.component.css', '../../../../assets/css/guuk-theme.css']
})
export class GuukSubheaderComponent implements OnInit {
    @ViewChildren('navbar') navbar;
    @ViewChildren('filling') filling;

    public href: string;

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.href = this.router.url;

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.href = event.url;
            });
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        const element: any = event.srcElement;
        const scrollOffset = element.children[0].scrollTop;
        const offsetTop = this.filling.first.nativeElement.offsetTop;

        if (scrollOffset >= offsetTop) {
            if (!this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: ' + this.navbar.first.nativeElement.offsetHeight + 'px');
                this.navbar.first.nativeElement.classList.add('fixed-top');
            }
        } else {
            if (this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: 0px');
                this.navbar.first.nativeElement.classList.remove('fixed-top');
            }
        }
    }
}
