import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SimyoRateService} from '../../services/simyo-rate.service';
import {SimyoService} from '../../services/simyo.service';
import {fiberIndirectTechnologies, mobileContractType, orderType, rateAvailableIn, fibraSpeeds, countriesAllowedWithPassport, listTypeOperation} from 'src/app/shared/constantes';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SimyoShoppingCartService} from '../../services/simyo-shopping-cart.service';
import {Router} from '@angular/router';
import {SimyoOrderService} from '../../services/simyo-order.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DigoService} from 'src/app/services/digo.service';
import {SimyoGlobals} from '../../classes/simyo-globals';
import {Subscription, of} from 'rxjs';
import {setTotal} from '../redux/simyo.actions';
import {Store} from '@ngrx/store';
import {PermissionService} from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import { distinctUntilChanged, mergeAll, pairwise, startWith, tap } from 'rxjs/operators';

@Component({
    selector: 'app-simyo-rates',
    templateUrl: './simyo-rates.component.html',
    styleUrls: ['./simyo-rates.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoRatesComponent implements OnInit, OnDestroy {
    @Input() index = 0;
    @Input() fibraRate = '';
    @Input() showTypeContent = '';

    @ViewChild('content') content: NgbModalRef;
    @ViewChild('rememberPrepaid') rememberPrepaid: NgbModalRef;
    @ViewChild('notAllowedPrepaid') notAllowedPrepaid: NgbModalRef;
    @Output() onChangeRate = new EventEmitter<any>();
    @Output() requestRemovePostpaidsLines = new EventEmitter();

    public typeOrder: any = null;
    public loaded: boolean = false;
    public showErrorRate: boolean = false;
    public showErrorAdditionalRate: boolean = false;
    public allRates: any;
    public paramRates: any;
    //public bonosRates: any[] = [];
    public readyRates: any = {
        postpaid: [],
        prepaid: [],
        '': []
    };
    public allFibraRates: any[] = [];
    public fibraRates: any[] = [];
    public movilRates: any = {
        postpaid: [],
        prepaid: [],
        '': []
    };
    public diffVoice: any[] = [];
    public diffData: any[] = [];
    public showPostpaid: boolean = false;
    public showPrepaid: boolean = false;
    public formsData: any = null;
    public default_rate: any = null;
    public defaultDataIndex = 0;
    public defaultVoiceIndex = 0;
    public rateChanged: any;
    public rateChangedFtth: any;
    public changedIsPostpaid: boolean = false;
    public customRate: any = {
        price: 0,
        textFibra: '',
        textType: '',
        textRate: '',
        rates: {
            fibra: null,
            movil: null,
            //bonos: []
        }
    };
    public currentVoiceIndex = -1;
    public currentDataIndex = -1;

    public ratesFormGroup: FormGroup;
    public rechargePrice: any;
    private allSubscription: Subscription[] = [];
    public onlyPrepaid = false;

    public orderType = orderType;
    public allPermissions = allPermissions;
    public mobileContractType = mobileContractType;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;

    public customerData: any;
    public isAdditional: boolean = false;
    public order;
    public isNebaTechnology: boolean = false;
    public minimumMobilePrice = 7;
    public rateFtth = null;
    public rateChangedTerminal = null;
    public contRememberPrepaid = 1;

    //public broadbandSpeedSelected;
    public maxBroadbandSpeed = '0';

    showRateOnOffer = true;
    isStand = false;
    allowed18GB = false;
    showIconAdditional = false;
    constructor(
        private simyoService: SimyoService,
        private rateService: SimyoRateService,
        private shoppingCartService: SimyoShoppingCartService,
        private formBuilder: FormBuilder,
        private router: Router,
        private orderService: SimyoOrderService,
        private modalService: NgbModal,
        private digoService: DigoService,
        public simyoGlobals: SimyoGlobals,
        private store: Store<{ simyo: number }>,
        public permSv: PermissionService
    ) {

        this.ratesFormGroup = this.formBuilder.group({
            fibraRate: [this.fibraRate, Validators.required],
            dataRate: ['', Validators.required],
            voiceRate: ['', Validators.required],
            //bonosRate: new FormArray([]),
            movilType: ['', Validators.required],
            mobiles: [[]],
            rate: ['']
        });
    }

    ngOnInit(): void {
        this.allSubscription.push(this.ratesFormGroup.get('fibraRate').valueChanges
        .pipe(
            startWith(null),
            distinctUntilChanged(),
            pairwise()
          )
          .subscribe(([valorAnterior, speed]) => {
            let customerSpeed = this.typeOrder != orderType.BROADBAND ? this.simyoService.getMaxFtthSpeed() : this.simyoService.getMaxBroadbandCustomer()?.speed;
            speed = this.typeOrder != orderType.BROADBAND ? speed : '';
            this.maxBroadbandSpeed = this.getMaxFtthSpeed(customerSpeed, speed);
            this.showCorrectFibraRates();
            this.getDiffRates(this.allRates);
            this.setShowMobileType(this.allRates);
        }));

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.isStand = !!Number(data.info?.isstand);
                }
            })
        );

        this.allSubscription.push(of(this.simyoService.getDataFromCustomer(), this.simyoService.getDataFromNotCustomer())
        .pipe(
            mergeAll(),
            tap((data: any) => {
                if (data) {
                    if (parseInt(data.customer?.document_type_id) === 3) {
                        this.onlyPrepaid = !countriesAllowedWithPassport.includes(parseInt(data.customer.nationality_id));
                    }
                    if (parseInt(data.customer_doctype) === 3) {
                        this.onlyPrepaid = !countriesAllowedWithPassport.includes(parseInt(data.country));
                    }
                }
            })
        )
        .subscribe()
        );

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;
                switch (this.typeOrder) {
                    case orderType.CONVERGENT:
                        break;
                    case orderType.BROADBAND:
                        this.clearValidations('dataRate');
                        this.clearValidations('voiceRate');
                        this.clearValidations('movilType');
                        break;
                    case orderType.MOBILE:
                        this.clearValidations('fibraRate');
                        break;
                }
            })
        );

        this.allSubscription.push(this.rateService.getRates()
            .subscribe((data: any) => {
                if (data) {
                    this.isNebaTechnology = this.simyoService.getNebaNoCommercial();
                    data.RATES.FTTH_NEW = data.RATES.FTTH_NEW.map(rate => { return {...rate, frontShowBroadband: true}})
                    this.allRates = data;
                    this.paramRates = data.PARAMS;

                    this.loadData();
                    this.loaded = true;
                }
            })
        );



        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerData = data;
                this.remove100MB(this.allRates);
                this.loadData();

            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((order) => {
                if (order) {
                    this.order = order;
                    if (order.broadband?.rate_info?.speed && !this.showErrorRate && !this.isNebaTechnology) {
                        this.updateFtthRate(order.broadband.rate_info.speed.toString());
                    }
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (this.index !== null && data && data[this.index] && this.allRates) {
                    this.setMobileRate(data[this.index]);
                    //this.setBonosRates(data[this.index].bonos);
                    this.ratesFormGroup.patchValue({mobiles: data});
                }
            })
        );


        this.allSubscription.push(this.rateService.getErrorRate()
            .subscribe((data: boolean) => {
                this.showErrorRate = data;
            })
        );

        this.allSubscription.push(this.rateService.getErrorRateAdditional()
            .subscribe((data: boolean) => {
                this.showErrorAdditionalRate = data;
            })
        );

        // Retomar borrador
        this.allSubscription.push(this.simyoService.getFormsData()
            .subscribe((data: any) => {
                if (data != null) {
                    this.formsData = data;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                this.rateFtth = data;
                let broadbandHasNoDiscount = this.simyoService.getMaxFtthSpeed();
                if(broadbandHasNoDiscount != '0') {
                    this.updateFtthRate(broadbandHasNoDiscount, false);
                }
            })
        );

        if (this.simyoService.getIsChanging()) {
            this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data && typeof data[this.index] !== 'undefined') {
                        this.rateChanged = data[this.index];
                        this.rateChangedTerminal = data[this.index].terminal;
                        this.changeMobileType(this.rateChanged?.type_pay);
                    }
                })
            );

            if (this.rateChanged?.type_pay && this.rateChanged?.type_pay === 'postpaid') {
                this.changedIsPostpaid = true;
            }

            /*if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                this.allSubscription.push(this.simyoService.getRateFtth()
                .subscribe((data: any) => {
                    if (data) {
                        this.rateChangedFtth = data;
                        this.updateFtthRate(this.rateChangedFtth.rate_info.speed.toString());
                    }
                })
                );

            }*/
        }

        this.allSubscription.push(this.rateService.getShowRateOnOffer()
            .subscribe((data) => {
                if (data && data.index === this.index) {
                    this.showRateOnOffer = data.status;
                }
                if (this.isStand) {
                    this.showRateOnOffer = true;
                }
                if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                    this.showRateOnOffer = false;
                }

                if(this.simyoService.getIndexMainLine() < 0 || this.simyoService.getIndexMainLine() == this.index) {
                    this.showRateOnOffer = false;
                }
            })
        );
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    loadData() {
        this.showCorrectFibraRates();
        if (this.typeOrder && this.allRates) {
            this.getDiffRates(this.allRates);
            this.initForm();
            this.setShowMobileType(this.allRates);
        }
    }

    setShowMobileType(rates) {
        if(!rates) return;
        let type = rateAvailableIn.mobile;
        if (rates.RATES && rates.RATES.MOBILE) {
            if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                type = this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra?.rate_info.speed ?? '600');
            }
            if (this.typeOrder === orderType.MOBILE || (this.typeOrder === orderType.CONVERGENT && this.index > 0) && this.customRate) {
                type = rateAvailableIn.mobile;
            }

            const thereIsPostpaid = [...new Set(this.movilRates[mobileContractType.postpaid])];

            const thereIsPrepaid = [...new Set(this.movilRates[mobileContractType.prepaid])];

            this.showPostpaid = thereIsPostpaid.length ? true : false;
            this.showPrepaid = thereIsPrepaid.length && (!this.isNebaTechnology || this.isAdditional) ? true : false;

            this.initMovilType();
        }
    }

    getDiffRates(rates) {
        if(!rates) return;
        // FIBRA
        if (rates.RATES && rates.RATES.FTTH_NEW) {
            this.allFibraRates = rates.RATES.FTTH_NEW;
        }

        // BONOS
        // if (rates.BONOS) {
        //     this.bonosRates = rates.BONOS;
        //     this.bonosRates.map(x => (this.ratesFormGroup.get('bonosRate') as FormArray).push(new FormControl(false)));
        // }
        // Cuando hay que mostrar adicionales? Si hay linea principal y más de una linea pospago
        //if((this.simyoService.getMainLine() !== null && this.simyoService.getIndexMainLine() !== this.index)
        //|| (this.typeOrder === orderType.CONVERGENT && this.simyoService.getMainLineWithoutSpeed() !== null && this.simyoService.getMaxBroadbandCustomer() == null )) {
        let mainLineMatrix = this.simyoService.searchMainLineInMatrix(this.simyoService.getMainLineWithoutSpeed());
        //Caso para comprobar si tiene solo un movil no mostrar adicionales, ya que no tiene fibra.
        let checkOrderType = this.typeOrder === orderType.MOBILE ? this.simyoService.customerHasPackageOrMainLine() : true;

        if(mainLineMatrix !== undefined
            && mainLineMatrix !== null
            && (this.checkRateAvailableIn(mainLineMatrix.rate_info.available_in) || this.simyoService.customerHasPackage())
            && (this.simyoService.getTotalMobileLines() > 1
                || this.simyoService.getIndexMainLine() !== this.index)
            && checkOrderType) {
            this.isAdditional = true;
            //Show additionals
            this.showAdditionalsRates(rates, mobileContractType.postpaid);
            this.showAdditionalsRates(rates, mobileContractType.prepaid);
            this.showReadyAdditionalsRates(rates, mobileContractType.postpaid)
        } else {
            this.isAdditional = false;
            let ratesTmp = this.showRates(rates);
            this.movilRates[mobileContractType.postpaid] = ratesTmp.postpaid;
            this.movilRates[mobileContractType.prepaid] = ratesTmp.prepaid;
            this.readyRates[mobileContractType.postpaid] = this.showReadyRates(rates, mobileContractType.postpaid);
        }
        this.searchDefaultRate(true);

        // READY TO GO
        this.readyRates[mobileContractType.prepaid] = this.showReadyRates(rates, mobileContractType.prepaid);
    }

    checkIfExistPriceIntoBroadband(rate_info: any, speed_price: any) {
        if(!rate_info || !speed_price) {
            return true;
        }

        return rate_info[`ftth_${speed_price}_price`] !== undefined
        && rate_info[`ftth_${speed_price}_price`] !== null
        && rate_info[`ftth_${speed_price}_price`] !== '';
    }

    initForm() {
        if (this.typeOrder === orderType.BROADBAND || (this.typeOrder === orderType.CONVERGENT && this.index === 0)) {
            this.initFibraRate();
        }

        if (this.typeOrder === orderType.MOBILE || (this.typeOrder === orderType.CONVERGENT && this.index > 0)) {
            this.initMobileRate();
        }

        this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
    }

    // Inicializamos la tarifa por defecto de móvil
    initMobileRate() {
        let mobileType = this.ratesFormGroup.get('movilType').value;
        // Tarifa por defecto
        if (this.movilRates[mobileType] && this.movilRates[mobileType].length) {
            // Buscamos una por defecto
            let type = rateAvailableIn.mobile;
            if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                type = rateAvailableIn.broadband_300;
            }
            if (this.typeOrder === orderType.MOBILE || (this.typeOrder === orderType.CONVERGENT && this.index > 0)) {
                type = rateAvailableIn.mobile;
            }
            const compatibleRateDefault = this.movilRates[mobileType];
            const rateDefault = compatibleRateDefault.find(x => x.default_selected);

            if (rateDefault) {
                this.setMobileRate(rateDefault);
            } else {
                // Seleccionamos una de las listas para llevar
                if (compatibleRateDefault && compatibleRateDefault.length) {
                    this.setMobileRate(compatibleRateDefault[compatibleRateDefault.length - 1]);
                }
            }
        }

        this.initMovilType();
    }

    // Inicializamos la tarifa por defecto de fibra
    initFibraRate() {
        if (this.fibraRates && this.fibraRates.length) {
            const rateDefault = this.fibraRates.find(x => {
                if (this.fibraRate && this.fibraRate != '') {
                    return x.rate_info.speed === this.fibraRate;
                }

                return x.default_selected;
            });

            if (rateDefault) {
                this.updateFtthRate(rateDefault.rate_info.speed);
            } else {
                let speed = this.typeOrder === orderType.CONVERGENT ? fibraSpeeds.speed_1gb : '' ;
                this.updateFtthRate(speed);

            }
        }
    }

    initMovilType() {
        let mobileContractTypeSelected = '';
        if (!this.showPostpaid && this.showPrepaid) {
            mobileContractTypeSelected = mobileContractType.prepaid;
        }

        if (this.showPostpaid && !this.showPrepaid) {
            mobileContractTypeSelected = mobileContractType.postpaid;
        }

        if (this.showPostpaid && this.showPrepaid) {
            mobileContractTypeSelected = '';
        }

        if (!this.showPostpaid && !this.showPrepaid) {
            mobileContractTypeSelected = '';
        }

        // Flujo movil con pasaporte solo se muestra prepago
        if (this.typeOrder == orderType.MOBILE && this.onlyPrepaid) {
            this.showPostpaid = false;
            mobileContractTypeSelected = '';
        }

        this.changeMobileType(mobileContractTypeSelected)
    }

    fromPostpaidToPrepaid(){
        return (this.changedIsPostpaid && this.ratesFormGroup.get('movilType').value === 'prepaid' && !this.isAdditional && this.orderService.multiplePostpaids());
    }

    fromPrepaidToPostpaid(){
        return (!this.changedIsPostpaid && this.ratesFormGroup.get('movilType').value === 'postpaid' && !this.isAdditional && this.orderService.multiplePostpaids());
    }

    selectRate(rate, isReadyToBuy) {
        if(this.simyoService.getIsChanging()
            && this.orderService.paquetiza(this.index)
            && this.orderService.multiplePostpaids()
            && ((this.orderService.calculateMaxPrice(true) == 0) && rate.price < this.minimumMobilePrice)) {
                this.orderService.orderShowChangePackagedOrderDialog(this.index, this.fromPrepaidToPostpaid())
                .afterClosed()
                .subscribe((confirm: boolean) => {
                    if (confirm) {
                        this.orderService.removeAllPostpaidsExceptIndex(this.index);
                        this.confirmRate(rate, isReadyToBuy);
                        this.shoppingCartService.getStepper().selectedIndex = 0;
                    }

                });
                return;
        }
        if(this.showTypeContent === orderType.BROADBAND && this.typeOrder === orderType.CONVERGENT) {
            let speed = rate.rates?.fibra?.rate_info?.speed ?? rate.rate_info.speed;
            let mainLine = this.simyoService.getMainLine();
            let maxCurrentSpeed = this.simyoService.getMaxBroadbandCustomer() !== null ? this.simyoService.getMaxBroadbandCustomer().speed : null;
            let speedCheck = (maxCurrentSpeed == fibraSpeeds.speed_1gb || speed == fibraSpeeds.speed_1gb) ? fibraSpeeds.speed_1gb : (+maxCurrentSpeed > +speed ? maxCurrentSpeed : speed);
            let mainLineMatrix = this.simyoService.searchMainLineInMatrix(mainLine);
            if(mainLineMatrix != null) {
                let availableIn = mainLineMatrix.rate_info != null ? mainLineMatrix.rate_info.available_in.includes(`ftth_${speedCheck}`) : mainLineMatrix.rate_info.available_in.includes(`ftth_${speedCheck}`)
                if(availableIn == false) {
                    this.orderService.orderShowChangePackagedOrderDialog2(this.index).afterClosed()
                    .subscribe((confirm: boolean) => {
                        if (confirm) {
                            this.orderService.removeAllPostpaidsFromIndex(0);
                            this.confirmRate(rate, isReadyToBuy);
                        }
                    });
                    return;
                }
            }

        }
        this.confirmRate(rate, isReadyToBuy);

    }

    // Funcion que se lanza al seleccionar una tarifa en cualquiera de los 3 flujos
    confirmRate(rate, isReadyToBuy){
        let mobileType = this.ratesFormGroup.get('movilType').value;
        const newRate = JSON.parse(JSON.stringify(rate));
            this.rateService.setErrorRate(false);
            if (this.typeOrder === orderType.BROADBAND) {
                this.simyoService.setRateFtth(this.updateNewRate(newRate));
                this.shoppingCartService.getStepper().next();
            }
            //if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                if (this.typeOrder === orderType.CONVERGENT && this.showTypeContent !== orderType.MOBILE) {

                let fibra = this.rateFtth;
                let movil = newRate.rates?.movil ?? this.ratesFormGroup.get('mobiles').value[this.index];

                if(this.showTypeContent == '' || this.showTypeContent === orderType.BROADBAND) {
                    fibra = newRate.rates?.fibra ?? newRate;
                    movil = newRate.rates?.movil ?? this.ratesFormGroup.get('mobiles').value[this.index];

                    this.simyoService.setRateFtth(this.updateNewRate(fibra));
                }

                if(this.showTypeContent == '' || this.showTypeContent === orderType.MOBILE) {
                    newRate.rates.movil.type_pay = mobileType || this.ratesFormGroup.get('movilType').value;
                    //this.simyoService.addFirstRatesMobile({...newRate.rates.movil, bonos: newRate.rates.bonos});
                    //let mobile = {...newRate.rates.movil, bonos: newRate.rates.bonos, terminal: newRate.rates?.terminal};
                    let mobile = {...newRate.rates.movil, terminal: newRate.rates?.terminal};
                    this.simyoService.setRatesMobile(this.index, mobile, this.order);
                }

                this.simyoService.setRateFtth(this.updateNewRate(fibra));

                this.shoppingCartService.getStepper().next();
            }

            if (this.typeOrder === orderType.MOBILE || (this.typeOrder === orderType.CONVERGENT && this.showTypeContent === orderType.MOBILE)) {
                if (isReadyToBuy) {
                    newRate.type_pay = this.ratesFormGroup.get('movilType').value;
                    //this.simyoService.setRatesMobile(this.index, {...newRate, bonos: [], terminal: this.rateChangedTerminal}, this.order);
                    this.simyoService.setRatesMobile(this.index, {...newRate, terminal: this.rateChangedTerminal}, this.order);
                    if (this.typeOrder === orderType.CONVERGENT) {
                        this.simyoService.setRateFtth(this.updateNewRate(this.rateFtth));
                    }
                } else {
                    newRate.rates.movil.type_pay = mobileType || this.ratesFormGroup.get('movilType').value;
                    //this.simyoService.setRatesMobile(this.index, {...newRate.rates.movil, bonos: newRate.rates.bonos, terminal: newRate.rates?.terminal}, this.order);
                    this.simyoService.setRatesMobile(this.index, {...newRate.rates.movil, terminal: newRate.rates?.terminal}, this.order);
                    if (this.typeOrder === orderType.CONVERGENT) {
                        this.simyoService.setRateFtth(this.updateNewRate(this.rateFtth));
                    }
                }
            }

            if (this.typeOrder === orderType.CONVERGENT && this.ratesFormGroup.get('movilType').value === mobileContractType.prepaid) {
                this.rechargePrice = this.rateService.getRechargePrice(+newRate.rates.movil.rate_info?.real_price);// + this.simyoGlobals.pricesBonos(newRate.rates.bonos));
                if (this.rechargePrice > 0) {
                    this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
                }
            }

            if (this.typeOrder === orderType.MOBILE && this.ratesFormGroup.get('movilType').value === mobileContractType.prepaid) {
                this.rechargePrice = isReadyToBuy ? this.rateService.getRechargePrice(+newRate.rate_info?.real_price) : this.rateService.getRechargePrice((+newRate.rates.movil.rate_info?.real_price));// + this.simyoGlobals.pricesBonos(newRate.rates.bonos)));
                if (this.rechargePrice > 0) {
                    this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
                }
            }
            this.simyoService.setIsChanging(false);

    }

    updateNewRate(fibra){
        fibra.rate_id = fibra.type_normal.rate_id;
        fibra.rate_info.price = fibra.type_normal.price;

        if(this.simyoService.getShowFtthReduced() && fibra.type_discount && fibra.type_discount?.rate_id) {
            let mainLine = this.simyoService.getMainLineWithoutSpeed();
            let mainLineMatrix = this.allRates.RATES.MOBILE.find(rate => rate.rate_info.product_code == mainLine.rate_info.product_code);
            let speed = Object.values(fibraSpeeds).includes(this.maxBroadbandSpeed) ? this.maxBroadbandSpeed : fibra.rate_info.speed;
            if((mainLineMatrix && mainLineMatrix.rate_info.available_in.includes(`ftth_${speed}`) )|| this.simyoService.customerHasPackage()) {
                fibra.rate_id = fibra.type_discount.rate_id;
                fibra.rate_info.price = fibra.type_discount.price;
            }
        }

        return fibra;
    }

    // Funcion que se llama para gestionar la informacion que se le pasa de la tarifa personalizada
    evaluateCustomRate(data) {
        this.initCustomRate();
        let mobileType = this.ratesFormGroup.get('movilType').value;
         // Tipo de línea móvil
        if (data.movilType && data.movilType !== '' && data.movilType !== '0') {
            this.customRate.textType = listTypeOperation.find(x => x.id == data.movilType)?.label;
        }
        let auxText = [];
        // Fibra
        if (data.fibraRate && data.fibraRate !== '' && this.index === 0) {
            var foundRate = this.fibraRates.find(rate => rate.rate_info.speed === data.fibraRate);
            if (foundRate) {
                if (this.typeOrder === orderType.BROADBAND) {
                    foundRate.rate_info.price = foundRate.type_normal.price;
                    foundRate.rate_id = foundRate.type_normal.rate_id;
                    let speed = Object.values(fibraSpeeds).includes(this.maxBroadbandSpeed) ? this.maxBroadbandSpeed : foundRate.rate_info.speed;
                    let mainLine = this.simyoService.getMainLine();
                    if ((mainLine !== null && this.simyoService.getShowFtthReduced())
                    || this.simyoService.getCustomer()?.num_ftth.total_ftth < 3) {
                        let mainLineMatrix = this.simyoService.searchMainLineInMatrix(mainLine);
                        if((mainLineMatrix !== undefined && mainLineMatrix !== null && mainLineMatrix.rate_info?.available_in.includes(`ftth_${speed}`))
                        || this.simyoService.customerHasPackage()) {
                            foundRate.rate_info.price = foundRate.type_discount.price;
                            foundRate.rate_id = foundRate.type_discount.rate_id;
                        }
                    }
                }

                const found = foundRate;
                if(this.showTypeContent == '') {
                    this.customRate.price += +found.rate_info.price.replace(/,/g, '.');
                }
                this.customRate.textFibra = found.rate_info.speed;
                this.customRate.textFibra += found.rate_info.speed >= 100 ? ' Mb' : ' Gb';
                this.customRate.rates.fibra = found;
            }
        }
        // Tarifa movil
        // NO quitar !== null que hace validaciones con el 0
        if (data.dataRate !== null && data.dataRate !== ''
            && data.voiceRate !== null && data.voiceRate !== ''
            && this.diffVoice[+data.voiceRate] && this.diffData[+data.dataRate]) {
            const found = this.movilRates[mobileType]
                .filter(rate =>
                    rate.voice === this.diffVoice[+data.voiceRate].label &&
                    rate.data === this.diffData[+data.dataRate].label
                );

            if (found && found.length) {
                let auxFound: any;
                if (found.length > 1) {
                    if (this.customRate.rates.fibra) {
                        auxFound = found.find(rate => (mobileType === '' || rate.contract_type === mobileType) && rate.rate_info.available_in.includes(this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed)));
                    } else {
                        auxFound = found.find(rate => (mobileType === '' || rate.contract_type === mobileType) && rate.rate_info.available_in.includes(rateAvailableIn.mobile));
                    }
                } else {
                    auxFound = found[0];
                }

                let priceconvergente = '';
                if (this.customRate.rates.fibra && this.showTypeContent == '') {
                    priceconvergente = this.simyoGlobals.getFtthPrice(this.customRate.rates.fibra.rate_info.speed);
                    if (auxFound?.rate_info[priceconvergente] && auxFound.rate_info[priceconvergente] !== '') {
                        this.customRate.price = 0;
                        this.customRate.price += +auxFound.rate_info[priceconvergente];
                    }
                } else if(auxFound) {
                    this.customRate.price += +auxFound.rate_info.real_price.replace(/,/g, '.');
                }

                // this.customRate.price += +auxFound.rate_info.price.replace(/,/g, '.');
                auxText.push(auxFound?.data, auxFound?.voice);
                this.customRate.rates.movil = auxFound;
            }
        }
        // Bonos
        // if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
        //     this.customRate.rates.bonos.forEach(bono => {
        //         auxText.push(bono.rate_info.description);
        //         this.customRate.price += +bono.rate_info.price;
        //     });
        // }

        if (auxText && auxText.length) {
            this.customRate.textRate = auxText.join(' + ');
        }
    }
    // Funcion para mostrar que opciones de la configuracion están disponibles
    evaluateActiveOptions() {
        let type = rateAvailableIn.mobile;
        let mobileType = this.ratesFormGroup.get('movilType').value;

        if (this.customRate.rates.fibra) {
            type = this.simyoGlobals.getAvailableIn(this.maxBroadbandSpeed);
        }

        if(mobileType != null && mobileType != '') {
            let rateSelected = this.rateChanged ?? this.default_rate;
            let dataSelected = this.currentDataIndex > -1 ? this.diffData[this.currentDataIndex].label : rateSelected.data;
            let voiceSelected = this.currentVoiceIndex > -1 ? this.diffVoice[this.currentVoiceIndex].label : rateSelected.voice;

            if ((dataSelected !== null)) {

                // Obtenemos la tarifa de voz disponible para ese data
                const aux = this.movilRates[mobileType].filter(rate => rate.data === dataSelected);

                const auxOffer = this.movilRates[mobileType]
                    .filter(rate =>
                        rate.rate_info.available_in.includes(type) &&
                        rate.rate_ids[mobileType] !== '' &&
                        rate.data === dataSelected
                    );
                // Mostramos como deshabilitado si no lo encontramos

                this.diffVoice = this.movilRates[mobileType].map(item => {
                        let auxFound = aux.find(rate => rate.voice === item.voice);

                        return {
                            label: item.voice,
                            disabled: auxFound ? false : true,
                            isOffer: auxOffer.find(rate => rate.is_additional && rate.voice === item.voice && rate.rate_info?.old_price !== '') ? true : false,
                        };
                    })
                    .reduce(this.filterByValue, [])
                    .sort(this.compare);
                if (aux && aux.length === 0) {
                    this.initMobileRate();
                    return;
                }
                // Si la voz que hay seleccionada esta deshabilitada coger la primera que no lo este
                if (this.diffVoice.find(x => x.label === voiceSelected && x.disabled)) {
                    this.currentVoiceIndex = this.diffVoice.findIndex(x => x.disabled === false);
                    this.changeVoice(this.currentVoiceIndex);
                    return;
                }
            }

            if (voiceSelected !== null) {
                // Obtenemos la tarifa de voz disponible para ese data
                // Mostramos como deshabilitado si no lo encontramos
                this.showIconAdditional = false;
                this.diffData =  this.movilRates[mobileType]
                    .map(item => {
                        let isOffer = item.is_additional && item.rate_info?.old_price !== '';
                        if(this.showIconAdditional == false) {
                            this.showIconAdditional = isOffer;
                        }
                        return {
                            label: item.data,
                            disabled: false,
                            isOffer
                        };
                    })
                    .reduce(this.filterByValue, [])
                    .sort(this.compare);
            }


            this.defaultDataIndex = this.diffData.findIndex(x => x.label === dataSelected);
            this.defaultVoiceIndex = this.diffVoice.findIndex(x => x.label === voiceSelected);

            this.ratesFormGroup.patchValue({
                dataRate: this.defaultDataIndex,
                voiceRate: this.defaultVoiceIndex
            });
        }
    }

    filterByValue(accumulator, current) {
        const x = accumulator.find(item => item.label === current.label);

        if (!x) {
            return accumulator.concat([current]);
        } else {
            if (current.isOffer) {
                const foundIndex = accumulator.findIndex(item => item.label === current.label);
                if (foundIndex !== -1) {
                    accumulator[foundIndex].isOffer = current.isOffer;
                }
            }
            return accumulator;
        }
    }

    compare(a, b) {
        let labelA = parseInt(a.label.replace('MIN', '').replace('MB', '').replace('GB', ''), 10);
        let labelB = parseInt(b.label.replace('MIN', '').replace('MB', '').replace('GB', ''), 10);
        labelA = isNaN(labelA) ? 1 : labelA;
        labelB = isNaN(labelB) ? 1 : labelB;

        if (a.label.includes('ILIMIT')) {
            labelA = labelA * 1000000;
        } else if (a.label.includes('GB')) {
            labelA = labelA * 100;
        }

        if (b.label.includes('ILIMIT')) {
            labelB = labelB * 1000000;
        } else if (b.label.includes('GB')) {
            labelB = labelB * 100;
        }

        if (labelA < labelB) {
            return -1;
        }

        if (labelA > labelB) {
            return 1;
        }

        return 0;
    }

    // Funcion para añadir un bono a la tarifa
    // addBonoToCustomRate(rate, state) {
    //     if (state) {
    //         // Añadimos el bono si no está añadido
    //         const found = this.customRate.rates.bonos.find(x => rate.name === x.name);
    //         if (!found) {
    //             this.customRate.rates.bonos.push(rate);
    //         }
    //     } else {
    //         // Eliminamos ese bono de la tarifa
    //         if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
    //             const found = this.customRate.rates.bonos.findIndex(x => x.rate_ids.postpaid === rate.rate_ids.postpaid);
    //             if (found !== -1) {
    //                 this.customRate.rates.bonos.splice(found, 1);
    //             }
    //         }
    //     }
    //     if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
    //         this.customRate.rates.bonos.map(bono => {
    //             this.customRate.textRate += ' + ' + bono.rate_info.description;
    //             this.customRate.price += +bono.rate_info.price;
    //         });
    //     }

    //     this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
    // }
    // Funcion que evalua si la tarifa de fibra es correcta dependiendo si es cliente o no

    showCorrectFibraRates() {
        this.fibraRates = this.allFibraRates.filter(x => {
            return true;
        }).sort((a, b) => {
            if (a?.rate_info?.speed === '1') {
                return 1;
            }
            if (b?.rate_info?.speed === '1') {
                return -1;
            }
            if (a?.rate_info?.speed < b?.rate_info?.speed) {
                return -1;
            }
            if (a?.rate_info?.speed > b?.rate_info?.speed) {
                return 1;
            }
            return 0;
        });

        let only1gb = false;

        if(this.typeOrder === orderType.BROADBAND || this.showTypeContent === orderType.BROADBAND)  {
            only1gb = this.isNebaTechnology ? true : false;

        }
        this.fibraRates.forEach(rate => {
            rate.rate_info.price = rate.type_normal.price;
            rate.rate_id = rate.type_normal.rate_id;

            if(this.typeOrder === orderType.BROADBAND && this.simyoService.getCustomer()?.num_ftth.total_ftth < 3) {
                let mainLineWithoutSpeed = this.simyoService.getMainLineWithoutSpeed();
                let mainLineMatrix = this.simyoService.searchMainLineInMatrix(mainLineWithoutSpeed);
                let speed = Object.values(fibraSpeeds).includes(this.maxBroadbandSpeed) ? this.maxBroadbandSpeed : rate.rate_info.speed;
                if((mainLineMatrix !== undefined && mainLineMatrix !== null && mainLineMatrix.rate_info?.available_in.includes(`ftth_${speed}`)
                    || this.simyoService.customerHasPackage())) {
                    rate.rate_info.price = rate.type_discount.price;
                    rate.rate_id = rate.type_discount.rate_id;
                    only1gb = false;
                }
            }

            if (this.typeOrder !== orderType.BROADBAND && this.showTypeContent === orderType.BROADBAND ) {
                if (this.simyoService.getMainLine() !== null && this.simyoService.getShowFtthReduced()) {
                    rate.rate_info.price = rate.type_discount.price;
                    rate.rate_id = rate.type_discount.rate_id;

                    only1gb = false;
                }
            }
        });

        if (only1gb) {
            this.fibraRates = this.fibraRates.filter(rate =>
                rate.rate_info.speed === "1"
            )
        }

    }

    changeMobileType(mobileType) {
        this.ratesFormGroup.patchValue({
            movilType: mobileType
        });

        //Buscamos la tarifa por defecto
        this.searchDefaultRate(false);

        this.changeData();
        this.changeVoice();
        this.rateChanged = null;

        if (this.typeOrder === orderType.CONVERGENT) {
            if (mobileType === 'prepaid'
            &&  this.rememberPrepaid
            && this.contRememberPrepaid
            && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid_postpaid)
            && this.orderService.calculateMaxPrice() == 0) {
                this.contRememberPrepaid--;
                this.modalService.open(this.rememberPrepaid, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
            }
        }
    }

    changeVoice(newData = null) {
        this.currentVoiceIndex = -1;
        if(newData !== null) {
            this.currentVoiceIndex = newData
        }

        this.evaluateActiveOptions();
        this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
        this.setNewRate(this.diffData, this.ratesFormGroup.get('dataRate').value, 'dataRate');
    }

    changeData(newData = null) {
        this.currentDataIndex = -1;
        if(newData !== null) {
            this.currentDataIndex = newData;
        }

        this.evaluateActiveOptions();
        this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
        this.setNewRate(this.diffVoice, this.ratesFormGroup.get('voiceRate').value, 'voiceRate');
    }

    isAvailableRate(fibra?) {
        if (fibra) {
            this.onChangeRate.emit(fibra);
            //this.setBroadbandSpeed(fibra.rate_info.speed);
        }

        this.initMobileRate();
    }

    setNewRate(data, actualValue, formName) {

        if (data[+actualValue]?.disabled) {
            const found = data.findIndex(item => !item.disabled);
            if (found !== -1) {
                this.ratesFormGroup.get(formName).patchValue(found);
            }
        }
    }

    switchToConvergent() {
        this.simyoService.setTypeOrder(orderType.CONVERGENT);
        this.simyoService.setPreventDestroy(true);
        this.simyoService.setRateFtth(null);
        if (this.formsData && this.formsData.fibra) {
            this.formsData.fibra = null;
            this.simyoService.setFormsData(this.formsData);
        }
        this.store.dispatch(setTotal({total: 0}));
        this.router.navigate(['simyo/convergente']).then(() => {
            this.simyoService.setPreventDestroy(false);
        });
    }

    setMobileRate(rateMobile) {
        this.currentVoiceIndex = this.diffVoice.findIndex(x => x.label === rateMobile?.voice);
        this.ratesFormGroup.get('dataRate').patchValue(this.diffData.findIndex(x => x.label === rateMobile?.data));
        this.ratesFormGroup.get('voiceRate').patchValue(this.diffVoice.findIndex(x => x.label === rateMobile?.voice));
        if (rateMobile?.type_pay !== undefined) {
            this.changeMobileType(rateMobile?.type_pay);
        }
    }

    // setBonosRates(rateBonos) {
    //     rateBonos.map(item => {
    //         (this.ratesFormGroup.get('bonosRate') as FormArray).at(this.bonosRates.findIndex(x => x.name === item.name)).patchValue(true);
    //         this.addBonoToCustomRate(item, true);
    //     });
    // }

    clearValidations(nameForm: string) {
        this.ratesFormGroup.get(nameForm).clearValidators();
        this.ratesFormGroup.get(nameForm).setValidators([]);
        this.ratesFormGroup.get(nameForm).updateValueAndValidity();
    }

    private remove100MB(rates){
        rates.RATES.FTTH_NEW.find
        const indice = rates.RATES.FTTH_NEW.findIndex(ftth => ftth.rate_info.speed === fibraSpeeds.speed_100);

        if (indice !== -1) {
            rates.RATES.FTTH_NEW[indice].frontShowBroadband = false;
        }
    }

    checkPermissions(type) {
        if(this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid_postpaid)) {
            return true;
        }
        if(type === 'postpaid' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_postpaid)) {
            if(this.ratesFormGroup.get('movilType').value !== type) {
                this.changeMobileType('postpaid');
            }

            return true;
        }

        if(type === 'prepaid' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid)) {
            if(this.ratesFormGroup.get('movilType').value !== type) {
                this.changeMobileType('prepaid');
            }
            return true;
        }

        return false;
    }

    showContent(typeOrder: string, firstItem = false) {
        if(this.showTypeContent) return this.showTypeContent === typeOrder;

        switch(typeOrder) {
            case orderType.BROADBAND:
                return this.typeOrder === orderType.BROADBAND;
            case orderType.MOBILE:
                return this.typeOrder === orderType.MOBILE;
            case orderType.CONVERGENT:
                return this.typeOrder === orderType.CONVERGENT && firstItem;
            default:
                return false;
        }
    }

    calculatePrice(customRate) {
        let total = customRate.price;
        let mobileType = this.ratesFormGroup.get('movilType').value;
        if(this.showTypeContent == ''
            && customRate.rates.fibra
            && mobileType == mobileContractType.postpaid) {
                let mainLine = this.simyoService.getMainLineWithoutSpeed();
                if((mainLine == null && this.typeOrder === orderType.CONVERGENT) || this.simyoService.getShowFtthReduced()) {
                    this.customRate.rates.fibra.rate_id = this.customRate.rates.fibra.type_discount.rate_id;
                    this.customRate.rates.fibra.rate_info.price = this.customRate.rates.fibra.type_discount.price;
                } else {
                    this.customRate.rates.fibra.rate_id = this.customRate.rates.fibra.type_normal.rate_id;
                    this.customRate.rates.fibra.rate_info.price = this.customRate.rates.fibra.type_normal.price;
                }

            total = +this.customRate.rates.fibra.rate_info.price;

            if(customRate.rates.movil) {
                total += +customRate.rates.movil.rate_info.real_price;
            }
        }

        if(customRate.rates.movil && this.showTypeContent == orderType.MOBILE) {
            total = +customRate.rates.movil.rate_info.real_price;
        }

        return total;
    }

    getRatesPostpaid(rates, speed) {
        let mainLine = this.simyoService.searchMainLineInMatrix(this.simyoService.getMainLine());
        let maxBroadbandCustomer = this.simyoService.getMaxBroadbandCustomer();
        return rates
            .filter(rate =>
                rate.rate_info.available_in.includes(speed)
                && rate.rate_ids.postpaid !== ''
                && ((mainLine == null && maxBroadbandCustomer != null && this.paramRates.FTTH_ACTIVE.includes(this.maxBroadbandSpeed))
                    ? (rate.rate_info.old_price !== '' ? rate.rate_info.old_price : +rate.rate_info.price) >= this.minimumMobilePrice : true)
                && ((this.allowed18GB && rate.data == '18 GB' && rate.voice == 'ILIMITADOS') || (this.checkIfExistPriceIntoBroadband(rate.rate_info, (this.simyoService.customerHasPackage() || this.paramRates.FTTH_ACTIVE.includes(this.maxBroadbandSpeed)) ? this.maxBroadbandSpeed : null)))
                && ((this.allowed18GB && rate.data == '18 GB' && rate.voice == 'ILIMITADOS') || (this.typeOrder === orderType.CONVERGENT ? this.checkRateAvailableIn(rate.rate_info.available_in) : true))
            )
    }

    getRatesPrepaid(rates,speed) {
        return rates
            .filter(rate =>
                rate.rate_info.available_in.includes(speed) &&
                    rate.rate_ids.prepaid !== ''
            )
    }

    searchDefaultRate(calculateDefaultIndex) {
        let mobileType = this.ratesFormGroup.get('movilType').value;
        this.default_rate = this.movilRates[mobileType].filter(x => x.default_selected)[0];
        this.defaultDataIndex = 0;
        this.defaultVoiceIndex = 0;
        if(calculateDefaultIndex) {
            if (this.default_rate) {
                this.defaultDataIndex = this.diffData.findIndex(x => x.label === this.default_rate.data);
                this.defaultVoiceIndex = this.diffVoice.findIndex(x => x.label === this.default_rate.voice);
            }
            if (this.rateChanged) {
                this.defaultDataIndex = this.diffData.findIndex(x => x.label === this.rateChanged.data);
                this.defaultVoiceIndex = this.diffVoice.findIndex(x => x.label === this.rateChanged.voice);
            }
        }
    }

    foundAdditionalRate(allRates, rate, mobileType) {
        const maxSecondPrice = this.orderService.calculateMaxPrice(true);
        const mainLine = this.simyoService.getMainLine();
        const totalMobileLine = this.simyoService.getTotalMobileLines();
        //Identificar cual es la principal, la del cliente o la del pedido
        const customerMaxMobile = this.simyoService.getMaxMobileCustomer();
        const mainLineIsCurrentOrder = this.simyoService.getIndexMainLine() > -1;

        let price = rate.is_additional ? +rate.rate_info.old_price : +rate.rate_info.price;
        /**Para mostrar lineas adicionales siendo la PRINCIPAL se tiene en cuenta:
         *  1 - Tengamos linea principal y posible linea secundaria
         *  2 - Haya mas de una línea
         *  3 - Buscar el precio de la linea secundaria y que sea mayor  al de la tarifa
         *      Este caso se da si tenemos una linea principal 150GB + Ilim y otra linea de 30GB + Ilim
         *      Si modificamos la de 150GB nos deberia aparecer a precio normal 30GB + Ilim, 50GB + Ilim y 150GB + Ilim
         *      El resto si las seleccionamos pasarian a adicional y la de 30GB + Ilim pasaria a ser principal
         *  4 - La tarifa tenga una homologa en el listado de adicionales
        */
        if( mobileType == 'postpaid'
            && mainLine !== null
            && totalMobileLine > 1
            && price < maxSecondPrice
            && rate.rate_ids.additional != ''
        ) {
            if(this.index === this.simyoService.getIndexMainLine()
                && +rate.rate_info.product_code == +mainLine.rate_info.product_code
            ) {
                return rate;
            }

            if(customerMaxMobile !== null
                && customerMaxMobile.is_additional == true
                && mainLineIsCurrentOrder == false
                && +rate.rate_info.product_code == customerMaxMobile.rate_info.product_code_refered) {
                    return rate;
            }
            return allRates.RATES.ADDITIONALS.find(rateMobile => rateMobile.rate_ids.postpaid == rate.rate_ids.additional || rateMobile.rate_ids.prepaid == rate.rate_ids.additional);
        }
        return null;
    }

    initCustomRate() {
        this.customRate = {
            ...this.customRate,
            price: 0,
            textFibra: '',
            textType: '',
            textRate: '',
            rates: {
                terminal: this.rateChangedTerminal
            }
        };
    }

    showAdditionalsRates(rates, mobileType) {
        if (rates.RATES && rates.RATES.ADDITIONALS) {
            const type = rateAvailableIn.mobile;
            let ratesAdditionals = this.getAuxRates(rates, mobileType);
            const auxdiffData = [...new Set(ratesAdditionals
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[mobileType] !== ''
                )
                .map(rate => rate && rate['data'])
                .filter(rate => rate))];
            this.showIconAdditional = false;
            this.diffData = auxdiffData
                .map(item => {
                    let isOffer = this.getOffer(ratesAdditionals, 'data', item);
                        if(this.showIconAdditional == false) {
                            this.showIconAdditional = isOffer;
                        }
                    return {
                        isOffer,
                        label: item,
                        disabled: false
                    };
                })
                .sort(this.compare);

            const auxDiffVoice = [...new Set(ratesAdditionals
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[mobileType] !== ''
                )
                .map(rate => rate && rate['voice'])
                .filter(rate => rate))];

            // Ponemos el ultimo elemento del array al principio
            auxDiffVoice.splice(0, 0, auxDiffVoice.splice(auxDiffVoice.length - 1, 1)[0]);

            this.diffVoice = auxDiffVoice
                .map((item: any) => {
                    return {
                        isOffer: false,
                        label: item,
                        disabled: false
                    };
                })
                .sort(this.compare);
            this.movilRates[mobileType] = ratesAdditionals;
        }
    }

    getAuxRates(rates, mobileType) {
        //let speed = this.simyoService.getMaxFtthSpeed();
        let speed = this.maxBroadbandSpeed == '0' && this.typeOrder == orderType.CONVERGENT ? fibraSpeeds.speed_1gb : this.maxBroadbandSpeed;
        //Identificar cual es la principal, la del cliente o la del pedido
        const customerMaxMobile = this.simyoService.getMaxMobileCustomer();
        const indexMainLine = this.simyoService.getIndexMainLine();
        const mainLineIsCurrentOrder = indexMainLine > -1;
        let maxPrice = this.orderService.calculateMaxPrice(indexMainLine == this.index);
        this.allowed18GB = this.simyoService.allowedRate18GBNormalPrice();
        if(indexMainLine == this.index && customerMaxMobile !== null) {
            this.allowed18GB = this.simyoService.allowedRate18GBNormalPrice(true);
            let secondLine = null;
            if(this.allowed18GB) {
                secondLine = this.orderService.getSecondMainLine() ?? customerMaxMobile;
            } else {
                secondLine = this.orderService.getSecondMainLine();
            }
            maxPrice = this.orderService.calculateMaxPrice(secondLine !== undefined && secondLine !== null);
        }

        if(mobileType == mobileContractType.postpaid) {
            let postpaidRates = this.showRates(rates).postpaid.filter(rate => {
                return (+rate.rate_info.real_price > maxPrice
                    || (+rate.rate_info.real_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true)
                    || (+rate.rate_info.real_price == maxPrice && mainLineIsCurrentOrder == true && indexMainLine == this.index)
                );
            });
            let additionalsRates = rates.RATES.ADDITIONALS.filter(rateAdd => {
                return this.changeAdditionalToNormal(rateAdd, maxPrice, mainLineIsCurrentOrder, customerMaxMobile, indexMainLine) == false
                && rateAdd.rate_info.available_in.includes(rateAvailableIn.mobile)
                && rateAdd.rate_ids[mobileType] !== '';
            })
            let orderRates = this.orderRates([...additionalsRates, ...postpaidRates]);
            return orderRates;
        }

        return rates.RATES.ADDITIONALS.map(rateAdd => {
            if((+rateAdd.rate_info.old_price > maxPrice
                    || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true)
                    || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == true && indexMainLine == this.index))
                && rateAdd.rate_ids.refered != '') {
                let foundMobile = rates.RATES.MOBILE.find(rateMobile => rateMobile.rate_info.available_in.includes(`ftth_${speed}`) && (rateMobile.rate_ids.postpaid == rateAdd.rate_ids.refered || rateMobile.rate_ids.prepaid == rateAdd.rate_ids.refered));
                /**
                 * Si encontramos el movil en las tarifas normales, comprobar lo siguient:
                 * La tarifa maxima es la que traia el cliente o una del order
                 * Si es la del cliente comprobar si es adicional, si es asi mostrar a precio normal, ya que la que trae es a precio reducido
                 */
                if(foundMobile)
                    return foundMobile;
            }
                return rateAdd;
        });

    }

    getOffer(rates, type, item): boolean {
        if (rates) {
            const found = rates.find(rate => rate[type] === item && rate.is_additional && rate.rate_info?.old_price !== '');
            if (found) {
                return true;
            }
        }
        return false;
    }

    calculateOldPrice(customRate) {
        let movil = customRate.rates.movil;
        let movilRateInfo = movil.rate_info;
            if(movilRateInfo.old_price
                && movilRateInfo.old_price !== ''
                && movil.show_additional_for_stand == false
            ) {
                return `${movilRateInfo.old_price}€`;
            }
        return '';

    }

    showRateConfigurator() {
        this.showRateOnOffer = false;
    }

    showRates(rates) {
        // MOVILES
        if (rates.RATES && rates.RATES.MOBILE) {
            const type = rateAvailableIn.mobile;
            let calculateRatesPostpaid = this.getRatesPostpaid(rates.RATES.MOBILE, type);
            const ratesPostpaid = calculateRatesPostpaid.map(rate => {
                    let additionalRate = this.foundAdditionalRate(rates, rate, mobileContractType.postpaid);
                    if(additionalRate) {
                            return additionalRate;
                    }
                    return rate;
                });

            const auxdiffData = [...new Set(calculateRatesPostpaid
                .map(rate => rate && rate['data'])
                .filter(rate => rate))];

            this.diffData = auxdiffData
                .map(item => {
                    return {
                        label: item,
                        disabled: false,
                        isOffer: false,
                    };
                })
                .sort(this.compare);

            const auxDiffVoice = [...new Set(calculateRatesPostpaid
                .map(rate => rate && rate['voice'])
                .filter(rate => rate))];

            // Ponemos el ultimo elemento del array al principio
            auxDiffVoice.splice(0, 0, auxDiffVoice.splice(auxDiffVoice.length - 1, 1)[0]);

            this.diffVoice = auxDiffVoice
                .map((item: any) => {
                    return {
                        label: item,
                        disabled: false,
                        isOffer: false,
                    };
                })
                .sort(this.compare);

            //Tiene sentido no filtrar las tarifas por prepaid
            const ratesPrepaid = rates.RATES.MOBILE;

            return {
                postpaid: ratesPostpaid,
                prepaid: ratesPrepaid,
            };
        }
    }

    showReadyRates(rates, mobileType) {
        if (rates.READY_TO_GO) {
            return rates.READY_TO_GO
                .filter(rate => this.isShowReadyToGo(mobileType, rate)
                )
                .map(rate => {
                    return {
                        ...rate,
                        contract_type: mobileType
                    };
                })
                .map(rate => {
                    let additionalRate = this.foundAdditionalRate(rates, rate, mobileContractType.postpaid);
                    if(additionalRate) {
                        return {...additionalRate, rate_info: {...additionalRate.rate_info, caption: rate.rate_info?.caption} };
                    }
                     return rate;
                })
                .sort((a, b) => {
                    if (+a.rate_info?.real_price < +b.rate_info?.real_price) {
                        return -1;
                    } else if (+a.rate_info?.real_price > +b.rate_info?.real_price) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            ;
        }
        return []
    }

    isShowReadyToGo(mobileType, rate) {
        return (mobileType === 'postpaid'
        ? (+rate.rate_info.price >= this.minimumMobilePrice
            && this.checkIfExistPriceIntoBroadband(rate.rate_info, this.maxBroadbandSpeed)
            && (this.typeOrder === orderType.CONVERGENT ? this.checkRateAvailableIn(rate.rate_info.available_in) : true)
        )
        : true)
    }

    showReadyAdditionalsRates(rates, mobileType) {
        const maxPrice = this.orderService.calculateMaxPrice();
        let _readyAdditionals = rates.RATES.ADDITIONALS.filter(rate => rate.is_additional || rate.show_additional_for_stand);
        //Identificar cual es la principal, la del cliente o la del pedido
        const customerMaxMobile = this.simyoService.getMaxMobileCustomer();
        const mainLineIsCurrentOrder = this.simyoService.getIndexMainLine() > -1;

        const indexMainLine = this.simyoService.getIndexMainLine();

        let ratesStandard = this.showReadyRates(rates, mobileType).filter(rate => ((+rate.rate_info.real_price > maxPrice || (this.index == indexMainLine && +rate.rate_info.real_price >= maxPrice) )
            || (+rate.rate_info.real_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true)));

        let _readyRates = _readyAdditionals.filter(rateAdd => {
            return false == (((+rateAdd.rate_info.old_price > maxPrice || (this.index == indexMainLine && +rateAdd.rate_info.old_price >= maxPrice))
                || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true))
                && rateAdd.rate_ids.refered != '');
        })
        _readyRates = this.orderRates([..._readyRates, ...ratesStandard]);
        this.readyRates[mobileType] = _readyRates
            .sort((a, b) => {
                if (+a.rate_info.real_price < +b.rate_info.real_price) {
                    return -1;
                } else if (+a.rate_info.real_price > +b.rate_info.real_price) {
                    return 1;
                } else {
                    return 0;
                }
            });
    }

    setBroadbandSpeed(speed) {
        //this.broadbandSpeedSelected = speed;
        //La fibra 100 tiene minimo de precio 10, resto de fibras su minimo es 7
        //this.minimumMobilePrice = this.broadbandSpeedSelected === fibraSpeeds.speed_100 ? 10 : 7;
    }

    getMaxFtthSpeed(currentSpeed, speedSelected) {
        if(currentSpeed == '1' || speedSelected == '1') return '1';
        return +currentSpeed > +speedSelected ? currentSpeed : speedSelected;
    }

    changeAdditionalToNormal(rateAdd, maxPrice, mainLineIsCurrentOrder, customerMaxMobile, indexMainLine) {
        return (+rateAdd.rate_info.old_price > maxPrice
            || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true)
            || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == true && indexMainLine == this.index))
        && rateAdd.rate_ids.refered != '';
    }

    getOrderIndex<T>(array: T[], value: T): number {
        const index = array.indexOf(value);
        return index === -1 ? Number.MAX_SAFE_INTEGER : index; // Si no encuentra, asignar un valor alto
    }

    orderRates(rates) {
        const orderData = this.paramRates.RATE_DATA;
        const orderVoice = this.paramRates.RATE_VOICE;

        return rates.sort((a, b) => {
            // Comparar por broadband primero
            const broadbandA = this.getOrderIndex(orderData, a.data);
            const broadbandB = this.getOrderIndex(orderData, b.data);

            if (broadbandA === broadbandB) {
                // Si son iguales, comparar por voice
                const voiceA = this.getOrderIndex(orderVoice, a.voice);
                const voiceB = this.getOrderIndex(orderVoice, b.voice);
                return voiceA - voiceB; // Comparar por voice
            }

            return broadbandA - broadbandB; // Comparar por broadband
        });
    }

    updateFtthRate(speed, compareSpeed = true) {
        let currentSpeed = this.ratesFormGroup.get('fibraRate').value;
        this.maxBroadbandSpeed = compareSpeed ? this.getMaxFtthSpeed(speed, currentSpeed) : speed;
        if(this.maxBroadbandSpeed != currentSpeed && this.paramRates.FTTH_ACTIVE.includes(this.maxBroadbandSpeed)) {
            let defaultSpeed = this.typeOrder === orderType.CONVERGENT ? fibraSpeeds.speed_1gb : '' ;
            let updateSpeed = this.paramRates.FTTH_ACTIVE.includes(this.maxBroadbandSpeed) ? this.maxBroadbandSpeed : (this.paramRates.FTTH_ACTIVE.includes(currentSpeed) ? currentSpeed : defaultSpeed);
            this.ratesFormGroup.get('fibraRate').patchValue(updateSpeed);
        }
    }

    checkRateAvailableIn(availableIn) {
        if(Object.values(fibraSpeeds).includes(this.maxBroadbandSpeed)) {
            return availableIn.includes(`ftth_${this.maxBroadbandSpeed}`);
        }
        return availableIn.filter(available => available.includes('ftth') > 0).length > 0;
    }
}
