<div #filling></div>

<nav #navbar id="navbar" class="navbar navbar-expand-lg bg-pepephone">
    <div class="container">
        <!-- Marca -->
        <a class="navbar-brand" routerLink="pepephone">
            <img src="../../../../assets/img/logo-pepephone.svg" class="img-fluid icon-nav2" width="130">
        </a>

        <div class="navbar-nav align-items-center mr-auto" [ngSwitch]="href">
            <span class="navbar-text contratacion" *ngSwitchCase="'/pepephone/listado'">
                Listado de operaciones
            </span>
        </div>

        <div class="my-2 my-lg-0">
            <a routerLink="/" *ngIf="!href.includes('/pepephone/order')" class="btn btn-white btn-sm">
                Volver al inicio
            </a>
            <a routerLink="/pepephone/listado" *ngIf="href.includes('/pepephone/order')" class="btn btn-white btn-sm">
                Volver al listado
            </a>
        </div>
    </div>
</nav>
