import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../shared/services/auth.service';
import {environment} from '../../../environments/environment';
import {MasterAllResponse} from '../models/master-all-response';
import {OrderListStatsResponse} from '../models/order-list-stats-response';
import {OrderListFilterResponse} from '../models/order-list-filter-response';
import {OrderListRequest} from '../models/order-list-request';
import {OrderInfoResponse} from '../models/order-info-response';

@Injectable({
    providedIn: 'root'
})
export class MasmovilService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
    }

    private getHeadersMasterApiKey() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json',
            //'x-api-key': '43379bba-beb6-41a1-a1c4-4bbc387b89ed'
        });
    }

    getMasterAllFromService(brand: string) {
        return this.http.get<MasterAllResponse>(`${environment.masmovilEndpoint}/${brand}/master/all`, {headers: this.getHeadersMasterApiKey()});
    }

    getOrderListStats(brand: string) {
        return this.http.post<OrderListStatsResponse>(`${environment.masmovilEndpoint}/${brand}/order/list/stats`, null, {headers: this.getHeadersMasterApiKey()});
    }

    getOrderListFilter(brand: string, request: OrderListRequest) {
        return this.http.post<OrderListFilterResponse>(`${environment.masmovilEndpoint}/${brand}/order/list/filter`, request, {headers: this.getHeadersMasterApiKey()});
    }

    getOrderInfo(brand: string, id: string) {
        return this.http.get<OrderInfoResponse>(`${environment.masmovilEndpoint}/${brand}/order/${id}`, {headers: this.getHeadersMasterApiKey()});
    }
}
