import {Component, OnInit} from '@angular/core';
import {Order, OrderMobile} from '../../../masmovil/models/order-list-filter-response';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MasmovilService} from '../../../masmovil/services/masmovil.service';
import {OrderInfoResponse} from '../../../masmovil/models/order-info-response';
import {UserNotFoundComponent} from '../../../shared/components/user-not-found/user-not-found.component';

const BRAND: string = 'pepephone';

@Component({
    selector: 'app-pepephone-order-detail',
    templateUrl: './pepephone-order-detail.component.html',
    styleUrls: ['./pepephone-order-detail.component.css', '../../../../assets/css/pepephone-theme.css']
})
export class PepephoneOrderDetailComponent implements OnInit {
    public loaded: boolean = false;
    public orderNotFound: boolean = false;

    public order: Order;

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private masmovilService: MasmovilService,
        public router: Router,
    ) {
    }

    ngOnInit(): void {
        this.getOrderInfo();
    }

    getOrderInfo(updateOrder?) {
        if (updateOrder) {
            this.loaded = false;
        }

        this.masmovilService.getOrderInfo(BRAND, this.activatedRoute.snapshot.paramMap.get('order_id'))
            .subscribe((data: OrderInfoResponse) => {
                if(data.msg.data.length === 0) {
                    this.router.navigate(['/pepephone/listado']);
                    return;
                }
                this.loaded = true;
                this.order = data.msg.data[0];
                this.orderNotFound = false;
            }, (error) => {
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'MásMóvil'
                        }
                    });
                } else {
                    this.orderNotFound = true;
                }
            });
    }

    getOrderStatus() {
        if (this.order) {
            if ((this.order.type == 'broadband' || this.order.type == 'convergent') && this.order.broadbands.length > 0) {
                return this.order.broadbands[0].status;
            }

            if (this.order.type == 'mobile' && this.order.mobiles.length > 0) {
                return this.order.mobiles[0].status;
            }

            if (this.order.type == 'sva' && this.order.services.length > 0) {
                return this.order.services[0].status;
            }
        }

        return '--';
    }

    getOrderTramitationDate() {
        if (this.order.tramited_at === null) {
            return '--';
        }

        const oldestDate = new Date(this.order.tramited_at);

        return new Intl.DateTimeFormat('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).format(oldestDate);
    }

    getTitleTypeMobile(mobile: OrderMobile) {
        if (mobile === undefined) {
            return '';
        }

        return mobile.is_portability ? `Línea móvil a portar ${mobile.msisdn}` : 'Alta línea móvil';

    }
}
