import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, width: number, fontSize: number = 16): string {
    if (!value || width <= 0) return '';

    // Estimación del ancho por carácter (ajústalo según la fuente usada)
    const charWidth = fontSize * 0.6; // 0.6 es un factor aproximado para fuentes comunes
    const maxChars = Math.floor(width / charWidth);

    if (value.length <= maxChars) {
      return value;
    }

    // Devuelve un string con tooltip integrado
    return `<span title='${value}'>${value.slice(0, maxChars - 3)}...</span>`;
  }
}
