import {Component, Input, OnInit} from '@angular/core';
import {HomeBrand} from '../../../../models/home-brand';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-hm-brand',
    templateUrl: './hm-brand.component.html',
    styleUrls: ['./hm-brand.component.css']
})
export class HmBrandComponent implements OnInit {
    @Input() brand: HomeBrand;
    public allPermissions = allPermissions;

    constructor(public permSv: PermissionService) {}

    ngOnInit(): void {
    }

    getCardClass() {
        return {
            [`card--brand`]: true,
            [`card--brand--${this.brand.prefix}`]: true,
            [`card--brand--2`]: this.brand.isOpen,
        };
    }

    toggleOpen() {
        this.brand.onToggle(!this.brand.isOpen ? this.brand.prefix : null);

        if (this.brand.canOpen) {
            this.brand.isOpen = !this.brand.isOpen;
        }
    }
}
