import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { superStatus, superStatusTranslate, tabHome } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { environment } from 'src/environments/environment';
import * as moment from 'moment/moment';
import { DigoService } from 'src/app/services/digo.service';
import { HomeService, INavigateFromOperation } from '../../services/home.service';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';

@Component({
    selector: 'app-hm-operations-posventa',
    templateUrl: './hm-operations-posventa.component.html',
    styleUrls: ['./hm-operations-posventa.component.css', '../../../../assets/css/home-theme.css']
})
export class HmOperationsPosventaComponent implements OnInit {
    @Input() initOperationPostpago: Observable<any>;
    public dataSource = [];
    public pdv: Ipdv;
    public orderListPdv: any;
    public body: any = {
        limit: 20,
        page: 1,
        brands: [],
        filters: {}
    };
    public loaded: boolean = false;
    public orderListPdvError: boolean = false;
    public filterBrandFormGroup: FormGroup;
    public customFilterFormGroup: FormGroup;
    public superStatusTranslate = superStatusTranslate;
    public superStatus = superStatus;
    public displayedColumns: string[] = ['name', 'type', 'msisdn','status', 'date', 'activation_date', 'brand'];
    public automaticLoad: boolean = true;
    public lastPage: number = 0;
    public allPermissions = allPermissions;
    private changeTabSubscription: Subscription;
    private allSubscription: Subscription[] = [];
    public dateControl = new FormControl(moment());
    public requestSubscribe;
    public saveDataOperationPosVenta: INavigateFromOperation;

    public brands = {
        orange: 'orange',
        jazztel: 'jazztel'
    };

    public typesLines = {
        broadband_new: 'Alta fibra',
        broadband_portability: 'Portabilidad fibra',
        mobile_new: 'Alta móvil',
        mobile_portability: 'Portabilidad móvil',
        mobile_renove: 'Renove',
        bono: 'Bono adicional'
    };

    constructor(
        private digoService: DigoService,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        public router: Router,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
        this.filterBrandFormGroup = this.formBuilder.group({
            orange: [true],
            jazztel: [true]
        });

        this.customFilterFormGroup = this.formBuilder.group({
            custom_search: [''],
            statuses: new FormControl({value: false, disabled: false}),
            date: new FormControl({value: false, disabled: false}),
            allBrands: new FormControl({value: true, disabled: false}),
            activationDate: new FormControl({value: false, disabled: false})
        });

        this.allSubscription.push(this.homeService.getNavigateFromOperationPosVenta()
            .subscribe((data: INavigateFromOperation) => {
                this.saveDataOperationPosVenta = data;
                if (this.saveDataOperationPosVenta) {
                    this.orderListPdv = data;
                    this.body = this.saveDataOperationPosVenta.body;
                    this.filterBrandFormGroup.patchValue(this.saveDataOperationPosVenta.forms.filterBrandForm);
                    this.customFilterFormGroup.patchValue(this.saveDataOperationPosVenta.forms.customFilterForm);
                    this.lastPage = this.saveDataOperationPosVenta.lastPage;
                    this.dataSource = this.saveDataOperationPosVenta.data;
                }
            })
        );

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data && this.pdv == null) {
                    this.pdv = data;
                }
            })
        );

        this.changeTabSubscription = this.initOperationPostpago
            .subscribe((data) => {
                if (data?.tab?.textLabel === tabHome.OPERACIONES_POSVENTA) {
                    this.cancelRequest();
                    if (
                        !(this.filterBrandFormGroup.get('jazztel').value && this.filterBrandFormGroup.get('orange').value &&
                        this.customFilterFormGroup.get('custom_search').value === '' &&
                        !this.customFilterFormGroup.get('statuses').value && !this.customFilterFormGroup.get('date').value &&
                        !this.customFilterFormGroup.get('activationDate').value)
                    ) {
                        this.filterBrandFormGroup.patchValue({orange: true, jazztel: true});
                        this.customFilterFormGroup.patchValue({custom_search: '', statuses: false, date: false, allBrands: true, activationDate: false});
                    }
                    this.body.filters = {};
                    this.changeFilter();
                }
            });
    }

    getOrderListPdv(marcas) {
        if (this.saveDataOperationPosVenta) {
            this.saveDataOperationPosVenta = null;
        }
        this.orderListPdvError = false;
        this.body.brands = this.filterIsActive(marcas, this.filterBrandFormGroup.getRawValue());
        this.body.filters.date = this.customFilterFormGroup.get('date').value ? 'bt|' + moment().clone().startOf('month').format('YYYY-MM-DD') + '|' + moment().format('YYYY-MM-DD') : undefined;
        this.body.filters.activation_date = this.customFilterFormGroup.get('activationDate').value ? 'bt|' + moment().clone().startOf('month').format('YYYY-MM-DD') + '|' + moment().format('YYYY-MM-DD') : undefined;
        this.body.filters.statuses = this.customFilterFormGroup.get('statuses').value ? [superStatus.active] : undefined;
        this.loaded = true;
        this.changeStateSliderToDisabled(true);
        this.requestSubscribe = this.homeService.getOrderListPdvPosVentaFromService(this.body)
            .subscribe((data: any) => {
                if (data) {
                    this.orderListPdv = data;
                    this.lastPage = data.last_page;
                    this.dataSource = this.dataSource.concat(data.data);
                }
                this.loaded = false;
                this.changeStateSliderToDisabled(false);
            }, () => {
                this.orderListPdvError = true;
                this.loaded = false;
                this.changeStateSliderToDisabled(false);
            });
    }

    cancelRequest() {
        if (this.requestSubscribe) {
            this.requestSubscribe.unsubscribe();
        }
    }

    changeStateSliderToDisabled(value) {
        if (value) {
            this.customFilterFormGroup.get('date').disable();
            this.customFilterFormGroup.get('activationDate').disable();
            this.customFilterFormGroup.get('statuses').disable();
            this.customFilterFormGroup.get('allBrands').disable();
        } else {
            this.customFilterFormGroup.get('date').enable();
            this.customFilterFormGroup.get('activationDate').enable();
            this.customFilterFormGroup.get('statuses').enable();
            this.customFilterFormGroup.get('allBrands').enable();
        }
    }

    allBrands() {
        if (this.customFilterFormGroup.get('allBrands').value && this.homeService.getBrandPermissionOperationsPosventa(this.brands).length !== this.filterIsActive(this.homeService.getBrandPermissionOperationsPosventa(this.brands), this.filterBrandFormGroup.getRawValue()).length) {
            this.filterBrandFormGroup.get('orange').patchValue(true);
            this.filterBrandFormGroup.get('jazztel').patchValue(true);
            this.changeFilter();
        } else {
            this.customFilterFormGroup.get('allBrands').patchValue(true);
        }
    }

    changeFilterBrand(brand, value) {
        if (this.homeService.getBrandPermissionOperationsPosventa(this.brands).length === this.filterIsActive(this.homeService.getBrandPermissionOperationsPosventa(this.brands), this.filterBrandFormGroup.getRawValue()).length && value) {
            this.customFilterFormGroup.get('allBrands').patchValue(true);
        } else {
            this.customFilterFormGroup.get('allBrands').patchValue(false);
        }
        if (this.homeService.getBrandPermissionOperationsPosventa(this.brands).length === this.body.brands.length && !value) {
            this.filterBrandFormGroup.get(this.brands.jazztel).patchValue(brand === this.brands.jazztel);
            this.filterBrandFormGroup.get(this.brands.orange).patchValue(brand === this.brands.orange);
            this.changeFilter();
        } else if (Object.keys(this.filterBrandFormGroup.getRawValue()).every((k) => !this.filterBrandFormGroup.getRawValue()[k])) {
            this.filterBrandFormGroup.get(brand).patchValue(true);
            this.customFilterFormGroup.get('allBrands').patchValue(false);
        } else {
            this.changeFilter();
        }
    }

    changeFilter() {
        if (this.customFilterFormGroup.get('activationDate').value && (this.customFilterFormGroup.get('date').value || !this.customFilterFormGroup.get('statuses').value)) {
            this.customFilterFormGroup.get('activationDate').patchValue(false);
        }
        this.body.page = 1;
        this.automaticLoad = true;
        this.orderListPdv = null;
        this.dataSource = [];
        this.getOrderListPdv(this.homeService.getBrandPermissionOperationsPosventa(this.brands));
    }

    changeFilterActivation(value) {
        if (value) {
            this.customFilterFormGroup.get('statuses').patchValue(true);
            this.customFilterFormGroup.get('date').patchValue(false);
        }
    }

    goDetailOrder(order) {
        switch (order.brand) {
            case this.brands.orange:
                this.homeService.setNavigateFromOperationPosVenta(this.getInfoOperation());
                this.homeService.setNavigateFromOperation(null);
                this.router.navigate(['orange/bonos-adicionales-order/' + order.element_id]);
                break;
            case this.brands.jazztel:
                window.open(environment.digoEndPoint + 'jazztel_list_pdv.itx');
                break;
            default:
                return '';
        }
    }

    getInfoOperation(): INavigateFromOperation {
        return {
            body: this.body,
            data: this.dataSource,
            lastPage: this.lastPage,
            forms: {
                filterBrandForm: this.filterBrandFormGroup.getRawValue(),
                customFilterForm: this.customFilterFormGroup.getRawValue()
            }
        };
    }

    search() {
        if (!this.loaded) {
            this.body.page = 1;
            this.body.filters.custom_search = this.customFilterFormGroup.get('custom_search').value;
            this.automaticLoad = true;
            this.orderListPdv = null;
            this.dataSource = [];
            this.getOrderListPdv(this.homeService.getBrandPermissionOperationsPosventa(this.brands));
        }
    }

    getImgBrand(brand) : string {
        switch (brand) {
            case this.brands.orange:
                return 'or-login-color.svg';
            case this.brands.jazztel:
                return 'jz-login-color.svg';
            default:
                return '';
        }
    }

    getStatusIcon(line) : string {
        switch (line.status) {
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
            case superStatus.unsubscribe:
            case superStatus.block:
                return 'x';
            case superStatus.draft:
                return 'file-text';
            case superStatus.in_process:
                return 'clock';
            case superStatus.active:
                return 'check';
            default:
                return '';
        }
    }

    getColorIcon(status) {
        switch (status) {
            case superStatus.active:
                return 'green';
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
            case superStatus.unsubscribe:
            case superStatus.block:
                return 'red';
            default:
                return '';
        }
    }

    convertDate(data): string {
        return data && data !== '' ? moment(data).format('DD/MM/YYYY') : '';
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad && !this.loaded) {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 100;

            if (scrollOffset > windowHeight) {
                this.automaticLoad = this.body.page >= 2 ? false : true;
                this.nextPage();
            }
        }
    }

    nextPage() {
        if (this.body.page < this.lastPage) {
            this.body.page += 1;
            this.getOrderListPdv(this.homeService.getBrandPermissionOperationsPosventa(this.brands));
        }
    }

    filterIsActive(brands, filters) {
        let auxBrand = JSON.parse(JSON.stringify(brands));
        if (brands && brands.length && filters) {
            for (let key of Object.keys(filters)) {
                if (!filters[key]) {
                    auxBrand = auxBrand.filter(x => x !== key)
                }
            }
        }
        return auxBrand;
    }


    ngOnDestroy() {
        this.changeTabSubscription.unsubscribe();
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
        this.cancelRequest();
    }

}
