import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PepephoneRoutingModule } from './pepephone-routing.module';
import { PepephoneListadoActivacionesComponent } from './components/pepephone-listado-activaciones/pepephone-listado-activaciones.component';
import { PepephoneOrderDetailComponent } from './components/pepephone-order-detail/pepephone-order-detail.component';
import { PepephoneSubheaderComponent } from './components/pepephone-subheader/pepephone-subheader.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SharedModule} from '../shared/shared.module';


@NgModule({
    declarations: [
        PepephoneListadoActivacionesComponent,
        PepephoneOrderDetailComponent,
        PepephoneSubheaderComponent
    ],
    exports: [
        PepephoneSubheaderComponent
    ],
    imports: [
        CommonModule,
        PepephoneRoutingModule,
        NgbDropdownModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class PepephoneModule { }
