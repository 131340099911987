<section class="procesos">
    <div class="row" *ngIf="!orderListPdvError">
        <div class="col-xl-12">
            <div class="card--sidebar mt-2">
                <div class="card-body">
                    <div class="row">
                        <div class="row col-xl-6" [formGroup]="customFilterFormGroup">
                            <div class="col-xl-8">
                                <mat-form-field>
                                    <mat-label>
                                        <i-feather name="search" class="icon--fe" stroke-width="2.5"></i-feather>
                                        <b> Buscador</b> <span class="search--cliente"> (Nombre / DNI / Nº de teléfono)</span>
                                    </mat-label>
                                    <input matInput formControlName="custom_search" [attr.disabled]="loaded ? 'disabled' : null" (keyup.enter)="search()">
                                    <mat-icon matSuffix (click)="search()" class="cursor-pointer">arrow_right_alt</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4">
                                <mat-form-field style="margin-top: 2px;">
                                    <mat-label>Tipo de alta</mat-label>
                                    <select matNativeControl formControlName="contract_type" (change)="changeFilter()" [attr.disabled]="loaded ? 'disabled' : null">
                                        <option value="">Todas</option>
                                        <option *ngFor="let type of listTypeOperation" [value]="type.id" [hidden]="type.id === 'broadband' && !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                            {{ type.label }}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <div class="row" style="padding-left: 30px;">
                                <div style="align-self: center;">
                                    <mat-slide-toggle formControlName="statuses" class="mr-4" (change)="changeFilter()">
                                        Finalizadas
                                    </mat-slide-toggle>
                                </div>
                                <div class="col">
                                    <mat-slide-toggle formControlName="date" (change)="changeFilter()">
                                        Solicitudes mes actual
                                    </mat-slide-toggle>
                                    <mat-slide-toggle formControlName="activationDate" (change)="changeFilterActivation($event.checked); changeFilter()">
                                        Activaciones mes actual
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 row mx-0 text-center border-left justify-content-center" [formGroup]="filterBrandFormGroup">
                            <div class="col-xl-12 row btn-group" style="align-items: center; margin-top: 10px;">
                                <label style="margin-left: 6px;" *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.bloquea_ventas)">
                                    <input type="checkbox" [formControlName]="brands.simyo" [value]="brands.simyo"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                                <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.jazztel.tuvo_activaciones)">
                                    <input type="checkbox" [formControlName]="brands.jazztel" [value]="brands.jazztel"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/jz-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                                <label title="Incluye Go" style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_prepago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_pospago)">
                                    <input type="checkbox" [formControlName]="brands.orange" [value]="brands.orange"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/or-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                                <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.masmovil.vende_masmovil)">
                                    <input type="checkbox" [formControlName]="brands.masmovil" [value]="brands.masmovil"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/mm-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                                <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.pepephone.vende_pepephone)">
                                    <input type="checkbox" [formControlName]="brands.pepephone" [value]="brands.pepephone"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/pp-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                                <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.guuk.vende_guuk)">
                                    <input type="checkbox" [formControlName]="brands.guuk" [value]="brands.guuk"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/gk-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                            </div>
                            <div class="col-xl-12" style="text-align: initial;" [formGroup]="customFilterFormGroup">
                                <mat-slide-toggle formControlName="allBrands" (change)="allBrands()" class="mt-1">
                                    Todas las marcas
                                </mat-slide-toggle>

                                <span align="right" class="pull-right" *ngIf="showButtonReport()">
                                    <a class="btn btn-gray--round btn-sm mb-0" style="font-size: 1rem !important;"
                                        (click)="datepicker.open()" *ngIf="!downloading"
                                        [title]="downloadErrorMsg && downloadErrorMsg !== '' ? downloadErrorMsg : ''">
                                        <i-feather name="arrow-down-circle" class="icon--fe cursor-pointer mr-2" stroke-width="2.5"></i-feather>
                                        <span>Informe</span>{{ downloadErrorMsg && downloadErrorMsg !== '' ? '*' : ''}}
                                    </a>
                                    <span *ngIf="downloading" class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>

                                    <span *ngIf="downloadErrorMsg && downloadErrorMsg !== ''" class="mt-1 custom-error-report">
                                        {{ downloadErrorMsg }}
                                    </span>

                                    <mat-form-field style="height: 0px; width: 0px; display: block;" class="p-0">
                                        <input matInput [matDatepicker]="datepicker" [max]="today">
                                        <mat-datepicker #datepicker startView="year"
                                            [calendarHeaderComponent]="customHeader"
                                            (monthSelected)="chosenMonthHandler($event, datepicker)"
                                            panelClass="custom-datepicker">
                                        </mat-datepicker>
                                    </mat-form-field>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12" *ngIf="orderListPdv">
                    <div class="card--sidebar mt-2 mb-2">
                        <div class="card-body">
                            <div class="col-xl-12 alert-operation info-notify" *ngIf="showSimyoMsg && dataSource.length">
                                <img src="./assets/img/sm-login-color.svg" class="img-fluid" width="100">
                                Tienes documentación pendiente de subir
                            </div>
                            <table mat-table [dataSource]="dataSource" *ngIf="dataSource.length" multiTemplateDataRows>
                                <!-- Nombre y documento de identidad -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Nombre </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element && element.is_contingency" ngbTooltip="Alta por soporte" style="float: left; margin-top: 8px; margin-bottom: 12px">
                                            <img src="./assets/img/support.png" alt="Alta por soporte" class="img-fluid mr-2" width="24" style="height: auto; position: relative;">
                                        </span>

                                        <div class="link-black-factura font-weight-bold">
                                            {{element.name}}
                                        </div>
                                        <div style="font-size: 12px;">
                                            {{element.document_type && element.document_type !== '' ? element.document_type + ':' : ''}} {{element.document_number}}
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Documento de identidad -->
                                <ng-container matColumnDef="document_number">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Nº de documento </th>
                                    <td mat-cell *matCellDef="let element"> {{element.document_number}} </td>
                                </ng-container>

                                <!-- Nº de contrato -->
                                <ng-container matColumnDef="contract_number">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Nº de contrato </th>
                                    <td mat-cell *matCellDef="let element"> {{element.contract_number}} </td>
                                </ng-container>

                                <!-- Tipo de alta -->
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Tipo de alta </th>
                                    <td mat-cell *matCellDef="let element" class="py-1">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div *ngFor="let line of element.lines" class="content-sub-row">
                                                {{ typesLines[line.type] }} {{ getTypeFiber(line) }} <span class="orange">{{ line.has_terminal ? '+ terminal' : '' }}</span>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Msisdn -->
                                <ng-container matColumnDef="msisdn">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Msisdn </th>
                                    <td mat-cell *matCellDef="let element" class="py-1">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div *ngFor="let line of element.lines" class="content-sub-row">
                                                {{ line.msisdn }}
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Estado -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Estado </th>
                                    <td mat-cell *matCellDef="let element" class="py-1">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div align="center" *ngFor="let line of element.lines" class="content-sub-row align-self-center" style="position: relative;">
                                                <i-feather [name]="getStatusIcon(line)" [style]="'color:' + getColorIcon(line.status)" class="icon--fe status-icon" stroke-width="2.5"
                                                    [title]="line.status_description && line.status_description !== '' ? line.status_description : superStatusTranslate[line.status]"
                                                    *ngIf="!(line.status === superStatus.active && line.status_description !== 'Activada pendiente 1ª llamada')">
                                                </i-feather>
                                                <i-feather [name]="'check'" class="icon--fe pending-activate status-icon" stroke-width="2.5"
                                                    [title]="line.status_description && line.status_description !== '' ? line.status_description : superStatusTranslate[line.status]"
                                                    *ngIf="line.status === superStatus.active && line.status_description === 'Activada pendiente 1ª llamada' && element.brand === brands.simyo">
                                                </i-feather>

                                                <ng-container *ngIf="line.status === superStatus.active && line.status_description !== 'Activada pendiente 1ª llamada'">
                                                    <i-feather name="check" style="color: green" class="icon--fe status-icon" stroke-width="2.5"
                                                               [title]="line.status_description && line.status_description !== '' ? line.status_description : superStatusTranslate[line.status]"></i-feather>
                                                    <i-feather name="check" style="color: green" class="icon--fe status-icon" stroke-width="2.5"
                                                               [title]="line.status_description && line.status_description !== '' ? line.status_description : superStatusTranslate[line.status]"></i-feather>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Documentacion -->
                                <ng-container matColumnDef="doc_status">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Documentación </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="align-self-center" align="center" *ngIf="element.brand === brands.simyo">
                                            <span class="{{ convertStatusClass(element.doc_status) }}" [ngClass]="{'animation-doc': showAnimationDoc(element)}" title="{{ element?.doc_status }}"></span>
                                        </div>
                                        <div class="align-self-center" align="center" *ngIf="element.brand !== brands.simyo">
                                            N/D
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Fecha -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Fecha solicitud </th>
                                    <td mat-cell *matCellDef="let element" align="center"> {{convertDate(element.date)}} </td>
                                </ng-container>

                                <!-- Fecha de activacion -->
                                <ng-container matColumnDef="activation_date">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Fecha activación </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div align="center" *ngFor="let line of element.lines" class="content-sub-row">
                                                {{convertDate(line.activation_date)}}
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Marca -->
                                <ng-container matColumnDef="brand">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Marca </th>
                                    <td mat-cell *matCellDef="let element">
                                        <img [src]="'./assets/img/' + getImgBrand(element.brand)" class="img-fluid" width="100" style="margin-left: -10px;" [title]="element?.brand === brands.orange ? 'Incluye Go' : ''">
                                        <i-feather *ngIf="showOrderLoad(element.lines) && element.brand === brands.simyo" (click)="$event.stopPropagation();updateStatus(element)" name="refresh-cw" class="icon--fe m-1 cursor-pointer" style="width: 15px !important; height: auto; color: #000;" [title]="element.error_update_full ? 'No se ha podido actualizar el estado' : 'Pulse para actualizar el pedido'"></i-feather>
                                        <span *ngIf="element.error_update_full">*</span>
                                    </td>
                                </ng-container>

                                <!-- Loading -->
                                <ng-container matColumnDef="loading">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> </th>
                                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                        <h5 align="center" class="tar-name-preload mb-0">
                                            Actualizando pedido...
                                        </h5>
                                        <div align="center" style="padding-bottom: 9px;">
                                            <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer" (click)="goDetailOrder(row)" [hidden]="row.update_full"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['loading'];" class="cursor-pointer" [hidden]="!row.update_full"></tr>
                            </table>
                            <div *ngIf="!dataSource.length && !loaded">
                                <h5 align="center" class="tar-name-preload">No se han encontrado operaciones para estos filtros</h5>
                            </div>
                            <div *ngIf="!automaticLoad && !loaded && body?.page < lastPage">
                                <button class="btn btn-black--outline btn-sm mb-1 mt-4 w-100" (click)="nextPage()">Cargar más</button>
                            </div>
                            <div *ngIf="loaded" class="col-xl-12">
                                <div class="my-3">
                                    <h5 align="center" class="tar-name-preload">Obteniendo operaciones...</h5>
                                    <div align="center">
                                        <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!orderListPdv" class="col-xl-12">
                    <div class="card--sidebar mt-2">
                        <div class="my-5">
                            <h5 align="center" class="tar-name-preload">Obteniendo operaciones...</h5>
                            <div align="center">
                                <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5" *ngIf="orderListPdvError">
        <h5 align="center" class="tar-name-preload">Se ha producido un error, inténtalo de nuevo más tarde</h5>
    </div>
</section>
