import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MasmovilListadoActivacionesComponent} from './components/masmovil-listado-activaciones/masmovil-listado-activaciones.component';
import {MasmovilOrderDetailComponent} from './components/masmovil-order-detail/masmovil-order-detail.component';

const routes: Routes = [
    {path: 'listado', component: MasmovilListadoActivacionesComponent},
    {path: 'order/:order_id', component: MasmovilOrderDetailComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MasmovilRoutingModule {
}
